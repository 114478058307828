@import "variables-pack1";

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tigo-icons-pack1';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brand-pt-tigo-money-full .path1 {
  &:before {
    content: $brand-pt-tigo-money-full-path1;
    color: rgb(0, 55, 125);
  }
}
.brand-pt-tigo-money-full .path2 {
  &:before {
    content: $brand-pt-tigo-money-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-money-full .path3 {
  &:before {
    content: $brand-pt-tigo-money-full-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-money-full .path4 {
  &:before {
    content: $brand-pt-tigo-money-full-path4;
    margin-left: -1em;
    color: rgb(250, 190, 0);
  }
}
.brand-pt-tigo-music-full .path1 {
  &:before {
    content: $brand-pt-tigo-music-full-path1;
    color: rgb(230, 35, 40);
  }
}
.brand-pt-tigo-music-full .path2 {
  &:before {
    content: $brand-pt-tigo-music-full-path2;
    margin-left: -1em;
    color: rgb(0, 55, 125);
  }
}
.brand-pt-tigo-music-full .path3 {
  &:before {
    content: $brand-pt-tigo-music-full-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-music-full .path4 {
  &:before {
    content: $brand-pt-tigo-music-full-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-music-full .path5 {
  &:before {
    content: $brand-pt-tigo-music-full-path5;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-onetv-full .path1 {
  &:before {
    content: $brand-pt-tigo-onetv-full-path1;
    color: rgb(27, 22, 28);
  }
}
.brand-pt-tigo-onetv-full .path2 {
  &:before {
    content: $brand-pt-tigo-onetv-full-path2;
    margin-left: -1em;
    color: rgb(0, 55, 125);
  }
}
.brand-pt-tigo-onetv-full .path3 {
  &:before {
    content: $brand-pt-tigo-onetv-full-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-onetv-full .path4 {
  &:before {
    content: $brand-pt-tigo-onetv-full-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-onetv-full .path5 {
  &:before {
    content: $brand-pt-tigo-onetv-full-path5;
    margin-left: -1em;
    color: rgb(68, 199, 244);
  }
}
.brand-pt-tigo-onetv-full .path6 {
  &:before {
    content: $brand-pt-tigo-onetv-full-path6;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-sports-full .path1 {
  &:before {
    content: $brand-pt-tigo-sports-full-path1;
    color: rgb(250, 190, 0);
  }
}
.brand-pt-tigo-sports-full .path2 {
  &:before {
    content: $brand-pt-tigo-sports-full-path2;
    margin-left: -1em;
    color: rgb(0, 55, 125);
  }
}
.brand-pt-tigo-sports-full .path3 {
  &:before {
    content: $brand-pt-tigo-sports-full-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-sports-full .path4 {
  &:before {
    content: $brand-pt-tigo-sports-full-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.logo-gt-amazon-primevideo {
  &:before {
    content: $logo-gt-amazon-primevideo;
    color: #565656;
  }
}
.logo-gt-android {
  &:before {
    content: $logo-gt-android;
    color: #565656;
  }
}
.logo-gt-facebook-logo-outline {
  &:before {
    content: $logo-gt-facebook-logo-outline;
    color: #565656;
  }
}
.logo-gt-facebook-logo {
  &:before {
    content: $logo-gt-facebook-logo;
    color: #565656;
  }
}
.brand-gt-go-contenedor-logo .path1 {
  &:before {
    content: $brand-gt-go-contenedor-logo-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-go-contenedor-logo .path2 {
  &:before {
    content: $brand-gt-go-contenedor-logo-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.logo-gt-instagram-logo-outline {
  &:before {
    content: $logo-gt-instagram-logo-outline;
    color: #565656;
  }
}
.logo-gt-Instagram-logo {
  &:before {
    content: $logo-gt-Instagram-logo;
    color: #565656;
  }
}
.logo-gt-ios {
  &:before {
    content: $logo-gt-ios;
    color: #565656;
  }
}
.brand-gt-mi-tigo-contenedor .path1 {
  &:before {
    content: $brand-gt-mi-tigo-contenedor-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-mi-tigo-contenedor .path2 {
  &:before {
    content: $brand-gt-mi-tigo-contenedor-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-mi-tigo-full .path1 {
  &:before {
    content: $brand-gt-mi-tigo-full-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-mi-tigo-full .path2 {
  &:before {
    content: $brand-gt-mi-tigo-full-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-mi-tigo {
  &:before {
    content: $brand-gt-mi-tigo;
    color: #565656;
  }
}
.logo-gt-office365 {
  &:before {
    content: $logo-gt-office365;
    color: #565656;
  }
}
.brand-gt-tigo-business-contenedor-logo .path1 {
  &:before {
    content: $brand-gt-tigo-business-contenedor-logo-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-business-contenedor-logo .path2 {
  &:before {
    content: $brand-gt-tigo-business-contenedor-logo-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-business-logo {
  &:before {
    content: $brand-gt-tigo-business-logo;
    color: #565656;
  }
}
.brand-gt-tigo-contenedor-logo .path1 {
  &:before {
    content: $brand-gt-tigo-contenedor-logo-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-contenedor-logo .path2 {
  &:before {
    content: $brand-gt-tigo-contenedor-logo-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-logo {
  &:before {
    content: $brand-gt-tigo-logo;
    color: #565656;
  }
}
.brand-gt-tigo-money-full .path1 {
  &:before {
    content: $brand-gt-tigo-money-full-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-money-full .path2 {
  &:before {
    content: $brand-gt-tigo-money-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-money {
  &:before {
    content: $brand-gt-tigo-money;
    color: #565656;
  }
}
.brand-gt-tigo-music-full .path1 {
  &:before {
    content: $brand-gt-tigo-music-full-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-music-full .path2 {
  &:before {
    content: $brand-gt-tigo-music-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-music {
  &:before {
    content: $brand-gt-tigo-music;
    color: #565656;
  }
}
.brand-gt-tigo-onetv-full .path1 {
  &:before {
    content: $brand-gt-tigo-onetv-full-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-onetv-full .path2 {
  &:before {
    content: $brand-gt-tigo-onetv-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-onetv {
  &:before {
    content: $brand-gt-tigo-onetv;
    color: #565656;
  }
}
.brand-gt-tigo-play-contenedor .path1 {
  &:before {
    content: $brand-gt-tigo-play-contenedor-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-play-contenedor .path2 {
  &:before {
    content: $brand-gt-tigo-play-contenedor-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-play-full .path1 {
  &:before {
    content: $brand-gt-tigo-play-full-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-play-full .path2 {
  &:before {
    content: $brand-gt-tigo-play-full-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-play {
  &:before {
    content: $brand-gt-tigo-play;
    color: #565656;
  }
}
.brand-gt-tigo-shop-contenedor .path1 {
  &:before {
    content: $brand-gt-tigo-shop-contenedor-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-shop-contenedor .path2 {
  &:before {
    content: $brand-gt-tigo-shop-contenedor-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-shop-full .path1 {
  &:before {
    content: $brand-gt-tigo-shop-full-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-shop-full .path2 {
  &:before {
    content: $brand-gt-tigo-shop-full-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-shop {
  &:before {
    content: $brand-gt-tigo-shop;
    color: #565656;
  }
}
.brand-gt-tigo-sports-full .path1 {
  &:before {
    content: $brand-gt-tigo-sports-full-path1;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-sports-full .path2 {
  &:before {
    content: $brand-gt-tigo-sports-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-gt-tigo-sports-full .path3 {
  &:before {
    content: $brand-gt-tigo-sports-full-path3;
    margin-left: -1em;
    color: rgb(86, 86, 86);
  }
}
.brand-gt-tigo-sports {
  &:before {
    content: $brand-gt-tigo-sports;
    color: #565656;
  }
}
.logo-nt-android {
  &:before {
    content: $logo-nt-android;
    color: #fff;
  }
}
.logo-nt-facebook-logo-outline .path1 {
  &:before {
    content: $logo-nt-facebook-logo-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.logo-nt-facebook-logo-outline .path2 {
  &:before {
    content: $logo-nt-facebook-logo-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.logo-nt-facebook-logo {
  &:before {
    content: $logo-nt-facebook-logo;
    color: #fff;
  }
}
.logo-nt-instagram-logo-outline .path1 {
  &:before {
    content: $logo-nt-instagram-logo-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.logo-nt-instagram-logo-outline .path2 {
  &:before {
    content: $logo-nt-instagram-logo-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.logo-nt-Instagram-logo {
  &:before {
    content: $logo-nt-Instagram-logo;
    color: #fff;
  }
}
.logo-nt-ios {
  &:before {
    content: $logo-nt-ios;
    color: #fff;
  }
}
.brand-nt-mi-tigo .path1 {
  &:before {
    content: $brand-nt-mi-tigo-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-nt-mi-tigo .path2 {
  &:before {
    content: $brand-nt-mi-tigo-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-nt-tigo-logo {
  &:before {
    content: $brand-nt-tigo-logo;
    color: #fff;
  }
}
.brand-nt-tigo-money .path1 {
  &:before {
    content: $brand-nt-tigo-money-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-nt-tigo-money .path2 {
  &:before {
    content: $brand-nt-tigo-money-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-nt-tigo-music .path1 {
  &:before {
    content: $brand-nt-tigo-music-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-nt-tigo-music .path2 {
  &:before {
    content: $brand-nt-tigo-music-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-nt-tigo-onetv .path1 {
  &:before {
    content: $brand-nt-tigo-onetv-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-nt-tigo-onetv .path2 {
  &:before {
    content: $brand-nt-tigo-onetv-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-nt-tigo-play .path1 {
  &:before {
    content: $brand-nt-tigo-play-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-nt-tigo-play .path2 {
  &:before {
    content: $brand-nt-tigo-play-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-nt-tigo-shop .path1 {
  &:before {
    content: $brand-nt-tigo-shop-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-nt-tigo-shop .path2 {
  &:before {
    content: $brand-nt-tigo-shop-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-nt-tigo-sports .path1 {
  &:before {
    content: $brand-nt-tigo-sports-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-nt-tigo-sports .path2 {
  &:before {
    content: $brand-nt-tigo-sports-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.logo-nt-twitter-logo-outline .path1 {
  &:before {
    content: $logo-nt-twitter-logo-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.logo-nt-twitter-logo-outline .path2 {
  &:before {
    content: $logo-nt-twitter-logo-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.logo-nt-twitter-logo {
  &:before {
    content: $logo-nt-twitter-logo;
    color: #fff;
  }
}
.logo-nt-youtube-logo-outline .path1 {
  &:before {
    content: $logo-nt-youtube-logo-outline-path1;
    color: rgb(255, 255, 255);
  }
}
.logo-nt-youtube-logo-outline .path2 {
  &:before {
    content: $logo-nt-youtube-logo-outline-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.logo-nt-youtube-logo {
  &:before {
    content: $logo-nt-youtube-logo;
    color: #fff;
  }
}
.logo-pt-amazon-primevideo .path1 {
  &:before {
    content: $logo-pt-amazon-primevideo-path1;
    color: rgb(34, 45, 65);
  }
}
.logo-pt-amazon-primevideo .path2 {
  &:before {
    content: $logo-pt-amazon-primevideo-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.logo-pt-android {
  &:before {
    content: $logo-pt-android;
    color: #00377b;
  }
}
.logo-pt-facebook-logo-outline .path1 {
  &:before {
    content: $logo-pt-facebook-logo-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.logo-pt-facebook-logo-outline .path2 {
  &:before {
    content: $logo-pt-facebook-logo-outline-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.logo-pt-facebook-logo {
  &:before {
    content: $logo-pt-facebook-logo;
    color: #00377b;
  }
}
.brand-pt-go-contenedor-logo .path1 {
  &:before {
    content: $brand-pt-go-contenedor-logo-path1;
    color: rgb(0, 55, 125);
  }
}
.brand-pt-go-contenedor-logo .path2 {
  &:before {
    content: $brand-pt-go-contenedor-logo-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.logo-pt-instagram-logo-outline .path1 {
  &:before {
    content: $logo-pt-instagram-logo-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.logo-pt-instagram-logo-outline .path2 {
  &:before {
    content: $logo-pt-instagram-logo-outline-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.logo-pt-Instagram-logo {
  &:before {
    content: $logo-pt-Instagram-logo;
    color: #00377b;
  }
}
.logo-pt-ios {
  &:before {
    content: $logo-pt-ios;
    color: #00377b;
  }
}
.brand-pt-mi-tigo-contenedor .path1 {
  &:before {
    content: $brand-pt-mi-tigo-contenedor-path1;
    color: rgb(0, 55, 124);
  }
}
.brand-pt-mi-tigo-contenedor .path2 {
  &:before {
    content: $brand-pt-mi-tigo-contenedor-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-mi-tigo-full .path1 {
  &:before {
    content: $brand-pt-mi-tigo-full-path1;
    color: rgb(0, 55, 124);
  }
}
.brand-pt-mi-tigo-full .path2 {
  &:before {
    content: $brand-pt-mi-tigo-full-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-mi-tigo-full .path3 {
  &:before {
    content: $brand-pt-mi-tigo-full-path3;
    margin-left: -2em;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-mi-tigo .path1 {
  &:before {
    content: $brand-pt-mi-tigo-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-mi-tigo .path2 {
  &:before {
    content: $brand-pt-mi-tigo-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.logo-pt-office365 .path1 {
  &:before {
    content: $logo-pt-office365-path1;
    color: rgb(0, 55, 123);
  }
}
.logo-pt-office365 .path2 {
  &:before {
    content: $logo-pt-office365-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-business-contenedor-logo .path1 {
  &:before {
    content: $brand-pt-tigo-business-contenedor-logo-path1;
    color: rgb(0, 55, 124);
  }
}
.brand-pt-tigo-business-contenedor-logo .path2 {
  &:before {
    content: $brand-pt-tigo-business-contenedor-logo-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-business-contenedor-logo .path3 {
  &:before {
    content: $brand-pt-tigo-business-contenedor-logo-path3;
    margin-left: -2em;
    color: rgb(109, 114, 120);
  }
}
.brand-pt-tigo-business-logo .path1 {
  &:before {
    content: $brand-pt-tigo-business-logo-path1;
    color: rgb(0, 55, 124);
  }
}
.brand-pt-tigo-business-logo .path2 {
  &:before {
    content: $brand-pt-tigo-business-logo-path2;
    margin-left: -2em;
    color: rgb(109, 114, 120);
  }
}
.brand-pt-tigo-contenedor-logo .path1 {
  &:before {
    content: $brand-pt-tigo-contenedor-logo-path1;
    color: rgb(0, 55, 123);
  }
}
.brand-pt-tigo-contenedor-logo .path2 {
  &:before {
    content: $brand-pt-tigo-contenedor-logo-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-logo {
  &:before {
    content: $brand-pt-tigo-logo;
    color: #00377b;
  }
}
.brand-pt-tigo-money .path1 {
  &:before {
    content: $brand-pt-tigo-money-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-money .path2 {
  &:before {
    content: $brand-pt-tigo-money-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.brand-pt-tigo-music .path1 {
  &:before {
    content: $brand-pt-tigo-music-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-music .path2 {
  &:before {
    content: $brand-pt-tigo-music-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.brand-pt-tigo-onetv .path1 {
  &:before {
    content: $brand-pt-tigo-onetv-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-onetv .path2 {
  &:before {
    content: $brand-pt-tigo-onetv-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.brand-pt-tigo-play-contenedor .path1 {
  &:before {
    content: $brand-pt-tigo-play-contenedor-path1;
    color: rgb(0, 55, 124);
  }
}
.brand-pt-tigo-play-contenedor .path2 {
  &:before {
    content: $brand-pt-tigo-play-contenedor-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-play-full .path1 {
  &:before {
    content: $brand-pt-tigo-play-full-path1;
    color: rgb(0, 55, 124);
  }
}
.brand-pt-tigo-play-full .path2 {
  &:before {
    content: $brand-pt-tigo-play-full-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-play-full .path3 {
  &:before {
    content: $brand-pt-tigo-play-full-path3;
    margin-left: -2em;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-play .path1 {
  &:before {
    content: $brand-pt-tigo-play-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-play .path2 {
  &:before {
    content: $brand-pt-tigo-play-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.brand-pt-tigo-shop-contenedor .path1 {
  &:before {
    content: $brand-pt-tigo-shop-contenedor-path1;
    color: rgb(0, 55, 124);
  }
}
.brand-pt-tigo-shop-contenedor .path2 {
  &:before {
    content: $brand-pt-tigo-shop-contenedor-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-shop-full .path1 {
  &:before {
    content: $brand-pt-tigo-shop-full-path1;
    color: rgb(0, 55, 124);
  }
}
.brand-pt-tigo-shop-full .path2 {
  &:before {
    content: $brand-pt-tigo-shop-full-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}
.brand-pt-tigo-shop-full .path3 {
  &:before {
    content: $brand-pt-tigo-shop-full-path3;
    margin-left: -2em;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-shop .path1 {
  &:before {
    content: $brand-pt-tigo-shop-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-shop .path2 {
  &:before {
    content: $brand-pt-tigo-shop-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.brand-pt-tigo-sports .path1 {
  &:before {
    content: $brand-pt-tigo-sports-path1;
    color: rgb(0, 200, 255);
  }
}
.brand-pt-tigo-sports .path2 {
  &:before {
    content: $brand-pt-tigo-sports-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.logo-pt-twitter-logo-outline .path1 {
  &:before {
    content: $logo-pt-twitter-logo-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.logo-pt-twitter-logo-outline .path2 {
  &:before {
    content: $logo-pt-twitter-logo-outline-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.logo-pt-twitter-logo {
  &:before {
    content: $logo-pt-twitter-logo;
    color: #00377b;
  }
}
.logo-pt-youtube-logo-outline .path1 {
  &:before {
    content: $logo-pt-youtube-logo-outline-path1;
    color: rgb(0, 55, 123);
  }
}
.logo-pt-youtube-logo-outline .path2 {
  &:before {
    content: $logo-pt-youtube-logo-outline-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.logo-pt-youtube-logo {
  &:before {
    content: $logo-pt-youtube-logo;
    color: #00377b;
  }
}
.gt-4g-large {
  &:before {
    content: $gt-4g-large;
    color: #565656;
  }
}
.gt-4g {
  &:before {
    content: $gt-4g;
    color: #565656;
  }
}
.gt-accougt-status {
  &:before {
    content: $gt-accougt-status;
    color: #565656;
  }
}
.gt-add-balance {
  &:before {
    content: $gt-add-balance;
    color: #565656;
  }
}
.gt-add-card {
  &:before {
    content: $gt-add-card;
    color: #565656;
  }
}
.gt-add-channels {
  &:before {
    content: $gt-add-channels;
    color: #565656;
  }
}
.gt-add-user {
  &:before {
    content: $gt-add-user;
    color: #565656;
  }
}
.gt-add {
  &:before {
    content: $gt-add;
    color: #565656;
  }
}
.gt-advanced-solutions-outline {
  &:before {
    content: $gt-advanced-solutions-outline;
    color: #565656;
  }
}
.gt-advanced-solutions .path1 {
  &:before {
    content: $gt-advanced-solutions-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-advanced-solutions .path2 {
  &:before {
    content: $gt-advanced-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.gt-agreement {
  &:before {
    content: $gt-agreement;
    color: #565656;
  }
}
.gt-analog-tv {
  &:before {
    content: $gt-analog-tv;
    color: #565656;
  }
}
.gt-apn {
  &:before {
    content: $gt-apn;
    color: #565656;
  }
}
.gt-app-premium {
  &:before {
    content: $gt-app-premium;
    color: #565656;
  }
}
.gt-app-store {
  &:before {
    content: $gt-app-store;
    color: #565656;
  }
}
.gt-apps-full {
  &:before {
    content: $gt-apps-full;
    color: #565656;
  }
}
.gt-apps-small {
  &:before {
    content: $gt-apps-small;
    color: #565656;
  }
}
.gt-apps {
  &:before {
    content: $gt-apps;
    color: #565656;
  }
}
.gt-at {
  &:before {
    content: $gt-at;
    color: #565656;
  }
}
.gt-balance-large {
  &:before {
    content: $gt-balance-large;
    color: #565656;
  }
}
.gt-balance {
  &:before {
    content: $gt-balance;
    color: #565656;
  }
}
.gt-banktransfer {
  &:before {
    content: $gt-banktransfer;
    color: #565656;
  }
}
.gt-bigdata {
  &:before {
    content: $gt-bigdata;
    color: #565656;
  }
}
.gt-billing {
  &:before {
    content: $gt-billing;
    color: #565656;
  }
}
.gt-billpayment_1 {
  &:before {
    content: $gt-billpayment_1;
    color: #565656;
  }
}
.gt-billpayment {
  &:before {
    content: $gt-billpayment;
    color: #565656;
  }
}
.gt-broadband-wifimodem {
  &:before {
    content: $gt-broadband-wifimodem;
    color: #565656;
  }
}
.gt-building {
  &:before {
    content: $gt-building;
    color: #565656;
  }
}
.gt-bundle {
  &:before {
    content: $gt-bundle;
    color: #565656;
  }
}
.gt-calendar-money {
  &:before {
    content: $gt-calendar-money;
    color: #565656;
  }
}
.gt-calendar-timer {
  &:before {
    content: $gt-calendar-timer;
    color: #565656;
  }
}
.gt-calendar {
  &:before {
    content: $gt-calendar;
    color: #565656;
  }
}
.gt-call-rerouting {
  &:before {
    content: $gt-call-rerouting;
    color: #565656;
  }
}
.gt-call {
  &:before {
    content: $gt-call;
    color: #565656;
  }
}
.gt-calls-large {
  &:before {
    content: $gt-calls-large;
    color: #565656;
  }
}
.gt-calls {
  &:before {
    content: $gt-calls;
    color: #565656;
  }
}
.gt-cash {
  &:before {
    content: $gt-cash;
    color: #565656;
  }
}
.gt-check-hexagon {
  &:before {
    content: $gt-check-hexagon;
    color: #565656;
  }
}
.gt-check {
  &:before {
    content: $gt-check;
    color: #565656;
  }
}
.gt-clear {
  &:before {
    content: $gt-clear;
    color: #565656;
  }
}
.gt-clock {
  &:before {
    content: $gt-clock;
    color: #565656;
  }
}
.gt-closed-wifi {
  &:before {
    content: $gt-closed-wifi;
    color: #565656;
  }
}
.gt-cloud-solutions-outline {
  &:before {
    content: $gt-cloud-solutions-outline;
    color: #565656;
  }
}
.gt-cloud-solutions .path1 {
  &:before {
    content: $gt-cloud-solutions-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-cloud-solutions .path2 {
  &:before {
    content: $gt-cloud-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.gt-cloud {
  &:before {
    content: $gt-cloud;
    color: #565656;
  }
}
.gt-cloudbackup {
  &:before {
    content: $gt-cloudbackup;
    color: #565656;
  }
}
.gt-cloudfirewall {
  &:before {
    content: $gt-cloudfirewall;
    color: #565656;
  }
}
.gt-code {
  &:before {
    content: $gt-code;
    color: #565656;
  }
}
.gt-complaints {
  &:before {
    content: $gt-complaints;
    color: #565656;
  }
}
.gt-conference-call {
  &:before {
    content: $gt-conference-call;
    color: #565656;
  }
}
.gt-connectivity-01 {
  &:before {
    content: $gt-connectivity-01;
    color: #565656;
  }
}
.gt-connectivity-solutions-outline {
  &:before {
    content: $gt-connectivity-solutions-outline;
    color: #565656;
  }
}
.gt-connectivity-solutions .path1 {
  &:before {
    content: $gt-connectivity-solutions-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-connectivity-solutions .path2 {
  &:before {
    content: $gt-connectivity-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.gt-connectivity {
  &:before {
    content: $gt-connectivity;
    color: #565656;
  }
}
.gt-contac-backup {
  &:before {
    content: $gt-contac-backup;
    color: #565656;
  }
}
.gt-contac-list {
  &:before {
    content: $gt-contac-list;
    color: #565656;
  }
}
.gt-contact-backup {
  &:before {
    content: $gt-contact-backup;
    color: #565656;
  }
}
.gt-contact-phone {
  &:before {
    content: $gt-contact-phone;
    color: #565656;
  }
}
.gt-convergence-tvinternet .path1 {
  &:before {
    content: $gt-convergence-tvinternet-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-convergence-tvinternet .path2 {
  &:before {
    content: $gt-convergence-tvinternet-path2;
    margin-left: -2em;
    color: rgb(86, 86, 86);
    opacity: 0.5;
  }
}
.gt-convergence {
  &:before {
    content: $gt-convergence;
    color: #565656;
  }
}
.gt-corporativenoc {
  &:before {
    content: $gt-corporativenoc;
    color: #565656;
  }
}
.gt-Credentialbadge {
  &:before {
    content: $gt-Credentialbadge;
    color: #565656;
  }
}
.gt-customer-service {
  &:before {
    content: $gt-customer-service;
    color: #565656;
  }
}
.gt-datacenters-colocation {
  &:before {
    content: $gt-datacenters-colocation;
    color: #565656;
  }
}
.gt-dedicatedinternet {
  &:before {
    content: $gt-dedicatedinternet;
    color: #565656;
  }
}
.gt-delivery {
  &:before {
    content: $gt-delivery;
    color: #565656;
  }
}
.gt-dial {
  &:before {
    content: $gt-dial;
    color: #565656;
  }
}
.gt-discougt-alternate {
  &:before {
    content: $gt-discougt-alternate;
    color: #565656;
  }
}
.gt-discount {
  &:before {
    content: $gt-discount;
    color: #565656;
  }
}
.gt-dislike {
  &:before {
    content: $gt-dislike;
    color: #565656;
  }
}
.gt-dmzmodem {
  &:before {
    content: $gt-dmzmodem;
    color: #565656;
  }
}
.gt-doublearrow-left .path1 {
  &:before {
    content: $gt-doublearrow-left-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-doublearrow-left .path2 {
  &:before {
    content: $gt-doublearrow-left-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.gt-doublearrow-right .path1 {
  &:before {
    content: $gt-doublearrow-right-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-doublearrow-right .path2 {
  &:before {
    content: $gt-doublearrow-right-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.gt-download {
  &:before {
    content: $gt-download;
    color: #565656;
  }
}
.gt-edit {
  &:before {
    content: $gt-edit;
    color: #565656;
  }
}
.gt-error-circle {
  &:before {
    content: $gt-error-circle;
    color: #565656;
  }
}
.gt-error {
  &:before {
    content: $gt-error;
    color: #565656;
  }
}
.gt-expand-more {
  &:before {
    content: $gt-expand-more;
    color: #565656;
  }
}
.gt-family-plan {
  &:before {
    content: $gt-family-plan;
    color: #565656;
  }
}
.gt-faq {
  &:before {
    content: $gt-faq;
    color: #565656;
  }
}
.gt-file-interactive {
  &:before {
    content: $gt-file-interactive;
    color: #565656;
  }
}
.gt-file-list {
  &:before {
    content: $gt-file-list;
    color: #565656;
  }
}
.gt-file-listok {
  &:before {
    content: $gt-file-listok;
    color: #565656;
  }
}
.gt-file-refresh {
  &:before {
    content: $gt-file-refresh;
    color: #565656;
  }
}
.gt-file-time {
  &:before {
    content: $gt-file-time;
    color: #565656;
  }
}
.gt-file {
  &:before {
    content: $gt-file;
    color: #565656;
  }
}
.gt-filedhcp {
  &:before {
    content: $gt-filedhcp;
    color: #565656;
  }
}
.gt-firewall {
  &:before {
    content: $gt-firewall;
    color: #565656;
  }
}
.gt-fixed-line {
  &:before {
    content: $gt-fixed-line;
    color: #565656;
  }
}
.gt-friends {
  &:before {
    content: $gt-friends;
    color: #565656;
  }
}
.gt-geolocation-small {
  &:before {
    content: $gt-geolocation-small;
    color: #565656;
  }
}
.gt-geolocation {
  &:before {
    content: $gt-geolocation;
    color: #565656;
  }
}
.gt-googleapps {
  &:before {
    content: $gt-googleapps;
    color: #565656;
  }
}
.gt-guestnetwork {
  &:before {
    content: $gt-guestnetwork;
    color: #565656;
  }
}
.gt-hand-up {
  &:before {
    content: $gt-hand-up;
    color: #565656;
  }
}
.gt-help-outline {
  &:before {
    content: $gt-help-outline;
    color: #565656;
  }
}
.gt-home {
  &:before {
    content: $gt-home;
    color: #565656;
  }
}
.gt-hpdcloud {
  &:before {
    content: $gt-hpdcloud;
    color: #565656;
  }
}
.gt-idea-light {
  &:before {
    content: $gt-idea-light;
    color: #565656;
  }
}
.gt-imei {
  &:before {
    content: $gt-imei;
    color: #565656;
  }
}
.gt-incoming-call {
  &:before {
    content: $gt-incoming-call;
    color: #565656;
  }
}
.gt-incoming-outgoing-call-B {
  &:before {
    content: $gt-incoming-outgoing-call-B;
    color: #565656;
  }
}
.gt-incoming-outgoing-call {
  &:before {
    content: $gt-incoming-outgoing-call;
    color: #565656;
  }
}
.gt-info-full {
  &:before {
    content: $gt-info-full;
    color: #565656;
  }
}
.gt-info {
  &:before {
    content: $gt-info;
    color: #565656;
  }
}
.gt-internet-connection {
  &:before {
    content: $gt-internet-connection;
    color: #565656;
  }
}
.gt-internet-outline {
  &:before {
    content: $gt-internet-outline;
    color: #565656;
  }
}
.gt-internet-security {
  &:before {
    content: $gt-internet-security;
    color: #565656;
  }
}
.gt-internet .path1 {
  &:before {
    content: $gt-internet-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-internet .path2 {
  &:before {
    content: $gt-internet-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.gt-invite {
  &:before {
    content: $gt-invite;
    color: #565656;
  }
}
.gt-ip-calling {
  &:before {
    content: $gt-ip-calling;
    color: #565656;
  }
}
.gt-key-money {
  &:before {
    content: $gt-key-money;
    color: #565656;
  }
}
.gt-key-number {
  &:before {
    content: $gt-key-number;
    color: #565656;
  }
}
.gt-landline {
  &:before {
    content: $gt-landline;
    color: #565656;
  }
}
.gt-lend-me {
  &:before {
    content: $gt-lend-me;
    color: #565656;
  }
}
.gt-less {
  &:before {
    content: $gt-less;
    color: #565656;
  }
}
.gt-line-800 {
  &:before {
    content: $gt-line-800;
    color: #565656;
  }
}
.gt-linkdata {
  &:before {
    content: $gt-linkdata;
    color: #565656;
  }
}
.gt-list {
  &:before {
    content: $gt-list;
    color: #565656;
  }
}
.gt-local-remitances {
  &:before {
    content: $gt-local-remitances;
    color: #565656;
  }
}
.gt-location-ruler {
  &:before {
    content: $gt-location-ruler;
    color: #565656;
  }
}
.gt-long-distance-calls {
  &:before {
    content: $gt-long-distance-calls;
    color: #565656;
  }
}
.gt-m2m-machinetomachine {
  &:before {
    content: $gt-m2m-machinetomachine;
    color: #565656;
  }
}
.gt-mail-server {
  &:before {
    content: $gt-mail-server;
    color: #565656;
  }
}
.gt-menu-more {
  &:before {
    content: $gt-menu-more;
    color: #565656;
  }
}
.gt-menu {
  &:before {
    content: $gt-menu;
    color: #565656;
  }
}
.gt-message-read {
  &:before {
    content: $gt-message-read;
    color: #565656;
  }
}
.gt-mobile-balance01 {
  &:before {
    content: $gt-mobile-balance01;
    color: #565656;
  }
}
.gt-mobile-balance02 {
  &:before {
    content: $gt-mobile-balance02;
    color: #565656;
  }
}
.gt-mobile-data-large {
  &:before {
    content: $gt-mobile-data-large;
    color: #565656;
  }
}
.gt-mobile-data {
  &:before {
    content: $gt-mobile-data;
    color: #565656;
  }
}
.gt-mobile-extrabalance {
  &:before {
    content: $gt-mobile-extrabalance;
    color: #565656;
  }
}
.gt-mobile-outline {
  &:before {
    content: $gt-mobile-outline;
    color: #565656;
  }
}
.gt-mobile-phone {
  &:before {
    content: $gt-mobile-phone;
    color: #565656;
  }
}
.gt-mobile-promotionalbalance {
  &:before {
    content: $gt-mobile-promotionalbalance;
    color: #565656;
  }
}
.gt-mobile-wifi {
  &:before {
    content: $gt-mobile-wifi;
    color: #565656;
  }
}
.gt-mobile .path1 {
  &:before {
    content: $gt-mobile-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-mobile .path2 {
  &:before {
    content: $gt-mobile-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.gt-mobileinternet {
  &:before {
    content: $gt-mobileinternet;
    color: #565656;
  }
}
.gt-more-calls {
  &:before {
    content: $gt-more-calls;
    color: #565656;
  }
}
.gt-more {
  &:before {
    content: $gt-more;
    color: #565656;
  }
}
.gt-multipledevices {
  &:before {
    content: $gt-multipledevices;
    color: #565656;
  }
}
.gt-natmodem {
  &:before {
    content: $gt-natmodem;
    color: #565656;
  }
}
.gt-networkmonitoring {
  &:before {
    content: $gt-networkmonitoring;
    color: #565656;
  }
}
.gt-notebook-calendar {
  &:before {
    content: $gt-notebook-calendar;
    color: #565656;
  }
}
.gt-notebook-connectivity {
  &:before {
    content: $gt-notebook-connectivity;
    color: #565656;
  }
}
.gt-notebook-ip {
  &:before {
    content: $gt-notebook-ip;
    color: #565656;
  }
}
.gt-notebook-lanconnectivity {
  &:before {
    content: $gt-notebook-lanconnectivity;
    color: #565656;
  }
}
.gt-notebook-security {
  &:before {
    content: $gt-notebook-security;
    color: #565656;
  }
}
.gt-notebook-share {
  &:before {
    content: $gt-notebook-share;
    color: #565656;
  }
}
.gt-notebook {
  &:before {
    content: $gt-notebook;
    color: #565656;
  }
}
.gt-notification {
  &:before {
    content: $gt-notification;
    color: #565656;
  }
}
.gt-ok {
  &:before {
    content: $gt-ok;
    color: #565656;
  }
}
.gt-online-billing {
  &:before {
    content: $gt-online-billing;
    color: #565656;
  }
}
.gt-open-security {
  &:before {
    content: $gt-open-security;
    color: #565656;
  }
}
.gt-open-wifi {
  &:before {
    content: $gt-open-wifi;
    color: #565656;
  }
}
.gt-order {
  &:before {
    content: $gt-order;
    color: #565656;
  }
}
.gt-package-delivery {
  &:before {
    content: $gt-package-delivery;
    color: #565656;
  }
}
.gt-padlock {
  &:before {
    content: $gt-padlock;
    color: #565656;
  }
}
.gt-paquetigos {
  &:before {
    content: $gt-paquetigos;
    color: #565656;
  }
}
.gt-parentalcontrol {
  &:before {
    content: $gt-parentalcontrol;
    color: #565656;
  }
}
.gt-password-lock {
  &:before {
    content: $gt-password-lock;
    color: #565656;
  }
}
.gt-passwordok-lockconfirmed {
  &:before {
    content: $gt-passwordok-lockconfirmed;
    color: #565656;
  }
}
.gt-paused-call {
  &:before {
    content: $gt-paused-call;
    color: #565656;
  }
}
.gt-pay-merch {
  &:before {
    content: $gt-pay-merch;
    color: #565656;
  }
}
.gt-payment {
  &:before {
    content: $gt-payment;
    color: #565656;
  }
}
.gt-pdf {
  &:before {
    content: $gt-pdf;
    color: #565656;
  }
}
.gt-phone-history {
  &:before {
    content: $gt-phone-history;
    color: #565656;
  }
}
.gt-phone-settings {
  &:before {
    content: $gt-phone-settings;
    color: #565656;
  }
}
.gt-picture {
  &:before {
    content: $gt-picture;
    color: #565656;
  }
}
.gt-pin-code {
  &:before {
    content: $gt-pin-code;
    color: #565656;
  }
}
.gt-placement {
  &:before {
    content: $gt-placement;
    color: #565656;
  }
}
.gt-plan-tvinternet-telephony {
  &:before {
    content: $gt-plan-tvinternet-telephony;
    color: #565656;
  }
}
.gt-plan-tvinternet {
  &:before {
    content: $gt-plan-tvinternet;
    color: #565656;
  }
}
.gt-play-pause {
  &:before {
    content: $gt-play-pause;
    color: #565656;
  }
}
.gt-pluscall {
  &:before {
    content: $gt-pluscall;
    color: #565656;
  }
}
.gt-plusinternet {
  &:before {
    content: $gt-plusinternet;
    color: #565656;
  }
}
.gt-police {
  &:before {
    content: $gt-police;
    color: #565656;
  }
}
.gt-pos-posnet {
  &:before {
    content: $gt-pos-posnet;
    color: #565656;
  }
}
.gt-premium-plan {
  &:before {
    content: $gt-premium-plan;
    color: #565656;
  }
}
.gt-prioritydevice {
  &:before {
    content: $gt-prioritydevice;
    color: #565656;
  }
}
.gt-privatenetwork {
  &:before {
    content: $gt-privatenetwork;
    color: #565656;
  }
}
.gt-profilenotebook-user {
  &:before {
    content: $gt-profilenotebook-user;
    color: #565656;
  }
}
.gt-ptt-presstospeak {
  &:before {
    content: $gt-ptt-presstospeak;
    color: #565656;
  }
}
.gt-qosmodem {
  &:before {
    content: $gt-qosmodem;
    color: #565656;
  }
}
.gt-rec {
  &:before {
    content: $gt-rec;
    color: #565656;
  }
}
.gt-receive-banktranfer {
  &:before {
    content: $gt-receive-banktranfer;
    color: #565656;
  }
}
.gt-red {
  &:before {
    content: $gt-red;
    color: #565656;
  }
}
.gt-registry {
  &:before {
    content: $gt-registry;
    color: #565656;
  }
}
.gt-report {
  &:before {
    content: $gt-report;
    color: #565656;
  }
}
.gt-roaming-large {
  &:before {
    content: $gt-roaming-large;
    color: #565656;
  }
}
.gt-roaming {
  &:before {
    content: $gt-roaming;
    color: #565656;
  }
}
.gt-row-bottom {
  &:before {
    content: $gt-row-bottom;
    color: #565656;
  }
}
.gt-row-left {
  &:before {
    content: $gt-row-left;
    color: #565656;
  }
}
.gt-row-rigth {
  &:before {
    content: $gt-row-rigth;
    color: #565656;
  }
}
.gt-row-top {
  &:before {
    content: $gt-row-top;
    color: #565656;
  }
}
.gt-saas {
  &:before {
    content: $gt-saas;
    color: #565656;
  }
}
.gt-safetoschool {
  &:before {
    content: $gt-safetoschool;
    color: #565656;
  }
}
.gt-sd {
  &:before {
    content: $gt-sd;
    color: #565656;
  }
}
.gt-search-contact {
  &:before {
    content: $gt-search-contact;
    color: #565656;
  }
}
.gt-search {
  &:before {
    content: $gt-search;
    color: #565656;
  }
}
.gt-secure-pay {
  &:before {
    content: $gt-secure-pay;
    color: #565656;
  }
}
.gt-security-call {
  &:before {
    content: $gt-security-call;
    color: #565656;
  }
}
.gt-security-list {
  &:before {
    content: $gt-security-list;
    color: #565656;
  }
}
.gt-security-pending {
  &:before {
    content: $gt-security-pending;
    color: #565656;
  }
}
.gt-security-telephony {
  &:before {
    content: $gt-security-telephony;
    color: #565656;
  }
}
.gt-security-wifi {
  &:before {
    content: $gt-security-wifi;
    color: #565656;
  }
}
.gt-security {
  &:before {
    content: $gt-security;
    color: #565656;
  }
}
.gt-send-banktransfer {
  &:before {
    content: $gt-send-banktransfer;
    color: #565656;
  }
}
.gt-send-top-up {
  &:before {
    content: $gt-send-top-up;
    color: #565656;
  }
}
.gt-server-checkok {
  &:before {
    content: $gt-server-checkok;
    color: #565656;
  }
}
.gt-server-list {
  &:before {
    content: $gt-server-list;
    color: #565656;
  }
}
.gt-server-preferences {
  &:before {
    content: $gt-server-preferences;
    color: #565656;
  }
}
.gt-server-settings {
  &:before {
    content: $gt-server-settings;
    color: #565656;
  }
}
.gt-server-user {
  &:before {
    content: $gt-server-user;
    color: #565656;
  }
}
.gt-server {
  &:before {
    content: $gt-server;
    color: #565656;
  }
}
.gt-servers {
  &:before {
    content: $gt-servers;
    color: #565656;
  }
}
.gt-service-diagnostic {
  &:before {
    content: $gt-service-diagnostic;
    color: #565656;
  }
}
.gt-services {
  &:before {
    content: $gt-services;
    color: #565656;
  }
}
.gt-settings {
  &:before {
    content: $gt-settings;
    color: #565656;
  }
}
.gt-shaking-hands {
  &:before {
    content: $gt-shaking-hands;
    color: #565656;
  }
}
.gt-shopping-cart-2 {
  &:before {
    content: $gt-shopping-cart-2;
    color: #565656;
  }
}
.gt-shopping-cart {
  &:before {
    content: $gt-shopping-cart;
    color: #565656;
  }
}
.gt-signal-location {
  &:before {
    content: $gt-signal-location;
    color: #565656;
  }
}
.gt-signal {
  &:before {
    content: $gt-signal;
    color: #565656;
  }
}
.gt-sms-large {
  &:before {
    content: $gt-sms-large;
    color: #565656;
  }
}
.gt-sms {
  &:before {
    content: $gt-sms;
    color: #565656;
  }
}
.gt-special-services {
  &:before {
    content: $gt-special-services;
    color: #565656;
  }
}
.gt-specs {
  &:before {
    content: $gt-specs;
    color: #565656;
  }
}
.gt-speedcalculator-timer {
  &:before {
    content: $gt-speedcalculator-timer;
    color: #565656;
  }
}
.gt-star-circle {
  &:before {
    content: $gt-star-circle;
    color: #565656;
  }
}
.gt-star {
  &:before {
    content: $gt-star;
    color: #565656;
  }
}
.gt-statistics {
  &:before {
    content: $gt-statistics;
    color: #565656;
  }
}
.gt-store {
  &:before {
    content: $gt-store;
    color: #565656;
  }
}
.gt-subscriptions {
  &:before {
    content: $gt-subscriptions;
    color: #565656;
  }
}
.gt-target-server {
  &:before {
    content: $gt-target-server;
    color: #565656;
  }
}
.gt-television-full .path1 {
  &:before {
    content: $gt-television-full-path1;
    color: rgb(86, 86, 86);
  }
}
.gt-television-full .path2 {
  &:before {
    content: $gt-television-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.gt-television-outline {
  &:before {
    content: $gt-television-outline;
    color: #565656;
  }
}
.gt-television {
  &:before {
    content: $gt-television;
    color: #565656;
  }
}
.gt-ticket-GO {
  &:before {
    content: $gt-ticket-GO;
    color: #565656;
  }
}
.gt-ticket-money {
  &:before {
    content: $gt-ticket-money;
    color: #565656;
  }
}
.gt-ticketinformation {
  &:before {
    content: $gt-ticketinformation;
    color: #565656;
  }
}
.gt-tigo-backup {
  &:before {
    content: $gt-tigo-backup;
    color: #565656;
  }
}
.gt-tigo-online {
  &:before {
    content: $gt-tigo-online;
    color: #565656;
  }
}
.gt-timer {
  &:before {
    content: $gt-timer;
    color: #565656;
  }
}
.gt-top-up {
  &:before {
    content: $gt-top-up;
    color: #565656;
  }
}
.gt-triple-play {
  &:before {
    content: $gt-triple-play;
    color: #565656;
  }
}
.gt-tv-3d {
  &:before {
    content: $gt-tv-3d;
    color: #565656;
  }
}
.gt-tv-cable {
  &:before {
    content: $gt-tv-cable;
    color: #565656;
  }
}
.gt-tv-decoder {
  &:before {
    content: $gt-tv-decoder;
    color: #565656;
  }
}
.gt-tv-digital {
  &:before {
    content: $gt-tv-digital;
    color: #565656;
  }
}
.gt-tv-favoriteticket {
  &:before {
    content: $gt-tv-favoriteticket;
    color: #565656;
  }
}
.gt-tv-guide {
  &:before {
    content: $gt-tv-guide;
    color: #565656;
  }
}
.gt-tv-hd {
  &:before {
    content: $gt-tv-hd;
    color: #565656;
  }
}
.gt-tv-list {
  &:before {
    content: $gt-tv-list;
    color: #565656;
  }
}
.gt-tv-live {
  &:before {
    content: $gt-tv-live;
    color: #565656;
  }
}
.gt-tv-movies {
  &:before {
    content: $gt-tv-movies;
    color: #565656;
  }
}
.gt-tv-multiroom {
  &:before {
    content: $gt-tv-multiroom;
    color: #565656;
  }
}
.gt-tv-recommender {
  &:before {
    content: $gt-tv-recommender;
    color: #565656;
  }
}
.gt-tv-remotecontrol {
  &:before {
    content: $gt-tv-remotecontrol;
    color: #565656;
  }
}
.gt-tv-satellite {
  &:before {
    content: $gt-tv-satellite;
    color: #565656;
  }
}
.gt-tv-sofa {
  &:before {
    content: $gt-tv-sofa;
    color: #565656;
  }
}
.gt-tv-ticketvideo {
  &:before {
    content: $gt-tv-ticketvideo;
    color: #565656;
  }
}
.gt-tv-tigoplay {
  &:before {
    content: $gt-tv-tigoplay;
    color: #565656;
  }
}
.gt-tv-videochannels {
  &:before {
    content: $gt-tv-videochannels;
    color: #565656;
  }
}
.gt-tv-vod {
  &:before {
    content: $gt-tv-vod;
    color: #565656;
  }
}
.gt-twitter-logo-outline {
  &:before {
    content: $gt-twitter-logo-outline;
    color: #565656;
  }
}
.gt-twitter-logo {
  &:before {
    content: $gt-twitter-logo;
    color: #565656;
  }
}
.gt-unlimited-calls {
  &:before {
    content: $gt-unlimited-calls;
    color: #565656;
  }
}
.gt-unlimitedshippingpackages {
  &:before {
    content: $gt-unlimitedshippingpackages;
    color: #565656;
  }
}
.gt-user-chat {
  &:before {
    content: $gt-user-chat;
    color: #565656;
  }
}
.gt-user-exit {
  &:before {
    content: $gt-user-exit;
    color: #565656;
  }
}
.gt-user-invalid {
  &:before {
    content: $gt-user-invalid;
    color: #565656;
  }
}
.gt-user-logout {
  &:before {
    content: $gt-user-logout;
    color: #565656;
  }
}
.gt-user-profile {
  &:before {
    content: $gt-user-profile;
    color: #565656;
  }
}
.gt-user-protection {
  &:before {
    content: $gt-user-protection;
    color: #565656;
  }
}
.gt-user-question {
  &:before {
    content: $gt-user-question;
    color: #565656;
  }
}
.gt-user-valid {
  &:before {
    content: $gt-user-valid;
    color: #565656;
  }
}
.gt-user {
  &:before {
    content: $gt-user;
    color: #565656;
  }
}
.gt-users {
  &:before {
    content: $gt-users;
    color: #565656;
  }
}
.gt-velocity-speed {
  &:before {
    content: $gt-velocity-speed;
    color: #565656;
  }
}
.gt-video-surveillance {
  &:before {
    content: $gt-video-surveillance;
    color: #565656;
  }
}
.gt-virtual-servers {
  &:before {
    content: $gt-virtual-servers;
    color: #565656;
  }
}
.gt-voicemail {
  &:before {
    content: $gt-voicemail;
    color: #565656;
  }
}
.gt-volume {
  &:before {
    content: $gt-volume;
    color: #565656;
  }
}
.gt-waiting-call {
  &:before {
    content: $gt-waiting-call;
    color: #565656;
  }
}
.gt-web-asset {
  &:before {
    content: $gt-web-asset;
    color: #565656;
  }
}
.gt-web-hosting {
  &:before {
    content: $gt-web-hosting;
    color: #565656;
  }
}
.gt-widgets {
  &:before {
    content: $gt-widgets;
    color: #565656;
  }
}
.gt-wifi-highsignal {
  &:before {
    content: $gt-wifi-highsignal;
    color: #565656;
  }
}
.gt-wifi-lowsignal {
  &:before {
    content: $gt-wifi-lowsignal;
    color: #565656;
  }
}
.gt-wifi-mediumsignal {
  &:before {
    content: $gt-wifi-mediumsignal;
    color: #565656;
  }
}
.gt-world-server {
  &:before {
    content: $gt-world-server;
    color: #565656;
  }
}
.gt-xls {
  &:before {
    content: $gt-xls;
    color: #565656;
  }
}
.gt-youtube-logo-outline {
  &:before {
    content: $gt-youtube-logo-outline;
    color: #565656;
  }
}
.gt-youtube-logo {
  &:before {
    content: $gt-youtube-logo;
    color: #565656;
  }
}
.nt-4g-large {
  &:before {
    content: $nt-4g-large;
    color: #fff;
  }
}
.nt-4g .path1 {
  &:before {
    content: $nt-4g-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-4g .path2 {
  &:before {
    content: $nt-4g-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-account-status .path1 {
  &:before {
    content: $nt-account-status-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-account-status .path2 {
  &:before {
    content: $nt-account-status-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-add-balance .path1 {
  &:before {
    content: $nt-add-balance-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-add-balance .path2 {
  &:before {
    content: $nt-add-balance-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-add-card .path1 {
  &:before {
    content: $nt-add-card-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-add-card .path2 {
  &:before {
    content: $nt-add-card-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-add-channels .path1 {
  &:before {
    content: $nt-add-channels-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-add-channels .path2 {
  &:before {
    content: $nt-add-channels-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-add-user .path1 {
  &:before {
    content: $nt-add-user-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-add-user .path2 {
  &:before {
    content: $nt-add-user-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-add {
  &:before {
    content: $nt-add;
    color: #fff;
  }
}
.nt-advanced-solutions-outline .path1 {
  &:before {
    content: $nt-advanced-solutions-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-advanced-solutions-outline .path2 {
  &:before {
    content: $nt-advanced-solutions-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-advanced-solutions .path1 {
  &:before {
    content: $nt-advanced-solutions-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-advanced-solutions .path2 {
  &:before {
    content: $nt-advanced-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-agreement .path1 {
  &:before {
    content: $nt-agreement-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-agreement .path2 {
  &:before {
    content: $nt-agreement-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-analog-tv .path1 {
  &:before {
    content: $nt-analog-tv-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-analog-tv .path2 {
  &:before {
    content: $nt-analog-tv-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-apn .path1 {
  &:before {
    content: $nt-apn-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-apn .path2 {
  &:before {
    content: $nt-apn-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-app-premium .path1 {
  &:before {
    content: $nt-app-premium-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-app-premium .path2 {
  &:before {
    content: $nt-app-premium-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-app-store .path1 {
  &:before {
    content: $nt-app-store-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-app-store .path2 {
  &:before {
    content: $nt-app-store-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-apps-full .path1 {
  &:before {
    content: $nt-apps-full-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-apps-full .path2 {
  &:before {
    content: $nt-apps-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-apps-small {
  &:before {
    content: $nt-apps-small;
    color: #fff;
  }
}
.nt-apps {
  &:before {
    content: $nt-apps;
    color: #fff;
  }
}
.nt-at {
  &:before {
    content: $nt-at;
    color: #fff;
  }
}
.nt-balance-large {
  &:before {
    content: $nt-balance-large;
    color: #fff;
  }
}
.nt-balance .path1 {
  &:before {
    content: $nt-balance-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-balance .path2 {
  &:before {
    content: $nt-balance-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-banktransfer .path1 {
  &:before {
    content: $nt-banktransfer-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-banktransfer .path2 {
  &:before {
    content: $nt-banktransfer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-bigdata .path1 {
  &:before {
    content: $nt-bigdata-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-bigdata .path2 {
  &:before {
    content: $nt-bigdata-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-billing .path1 {
  &:before {
    content: $nt-billing-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-billing .path2 {
  &:before {
    content: $nt-billing-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-billpayment_1 .path1 {
  &:before {
    content: $nt-billpayment_1-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-billpayment_1 .path2 {
  &:before {
    content: $nt-billpayment_1-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-billpayment .path1 {
  &:before {
    content: $nt-billpayment-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-billpayment .path2 {
  &:before {
    content: $nt-billpayment-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-broadband-wifimodem .path1 {
  &:before {
    content: $nt-broadband-wifimodem-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-broadband-wifimodem .path2 {
  &:before {
    content: $nt-broadband-wifimodem-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-building .path1 {
  &:before {
    content: $nt-building-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-building .path2 {
  &:before {
    content: $nt-building-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-bundle .path1 {
  &:before {
    content: $nt-bundle-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-bundle .path2 {
  &:before {
    content: $nt-bundle-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-calendar-money .path1 {
  &:before {
    content: $nt-calendar-money-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-calendar-money .path2 {
  &:before {
    content: $nt-calendar-money-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-calendar-timer .path1 {
  &:before {
    content: $nt-calendar-timer-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-calendar-timer .path2 {
  &:before {
    content: $nt-calendar-timer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-calendar .path1 {
  &:before {
    content: $nt-calendar-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-calendar .path2 {
  &:before {
    content: $nt-calendar-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-call-rerouting .path1 {
  &:before {
    content: $nt-call-rerouting-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-call-rerouting .path2 {
  &:before {
    content: $nt-call-rerouting-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-call {
  &:before {
    content: $nt-call;
    color: #fff;
  }
}
.nt-calls-large {
  &:before {
    content: $nt-calls-large;
    color: #fff;
  }
}
.nt-calls .path1 {
  &:before {
    content: $nt-calls-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-calls .path2 {
  &:before {
    content: $nt-calls-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-cash .path1 {
  &:before {
    content: $nt-cash-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-cash .path2 {
  &:before {
    content: $nt-cash-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-check-hexagon .path1 {
  &:before {
    content: $nt-check-hexagon-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-check-hexagon .path2 {
  &:before {
    content: $nt-check-hexagon-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-check {
  &:before {
    content: $nt-check;
    color: #fff;
  }
}
.nt-clear {
  &:before {
    content: $nt-clear;
    color: #00c7ff;
  }
}
.nt-clock .path1 {
  &:before {
    content: $nt-clock-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-clock .path2 {
  &:before {
    content: $nt-clock-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-closed-wifi .path1 {
  &:before {
    content: $nt-closed-wifi-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-closed-wifi .path2 {
  &:before {
    content: $nt-closed-wifi-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-cloud-solutions-outline .path1 {
  &:before {
    content: $nt-cloud-solutions-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-cloud-solutions-outline .path2 {
  &:before {
    content: $nt-cloud-solutions-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-cloud-solutions .path1 {
  &:before {
    content: $nt-cloud-solutions-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-cloud-solutions .path2 {
  &:before {
    content: $nt-cloud-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-cloud {
  &:before {
    content: $nt-cloud;
    color: #fff;
  }
}
.nt-cloudbackup .path1 {
  &:before {
    content: $nt-cloudbackup-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-cloudbackup .path2 {
  &:before {
    content: $nt-cloudbackup-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-cloudfirewall .path1 {
  &:before {
    content: $nt-cloudfirewall-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-cloudfirewall .path2 {
  &:before {
    content: $nt-cloudfirewall-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-code {
  &:before {
    content: $nt-code;
    color: #fff;
  }
}
.nt-complaints .path1 {
  &:before {
    content: $nt-complaints-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-complaints .path2 {
  &:before {
    content: $nt-complaints-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-conference-call .path1 {
  &:before {
    content: $nt-conference-call-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-conference-call .path2 {
  &:before {
    content: $nt-conference-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-connectivity-01 .path1 {
  &:before {
    content: $nt-connectivity-01-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-connectivity-01 .path2 {
  &:before {
    content: $nt-connectivity-01-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-connectivity-solutions-outline .path1 {
  &:before {
    content: $nt-connectivity-solutions-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-connectivity-solutions-outline .path2 {
  &:before {
    content: $nt-connectivity-solutions-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-connectivity-solutions .path1 {
  &:before {
    content: $nt-connectivity-solutions-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-connectivity-solutions .path2 {
  &:before {
    content: $nt-connectivity-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-connectivity .path1 {
  &:before {
    content: $nt-connectivity-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-connectivity .path2 {
  &:before {
    content: $nt-connectivity-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-contac-backup .path1 {
  &:before {
    content: $nt-contac-backup-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-contac-backup .path2 {
  &:before {
    content: $nt-contac-backup-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-contac-list .path1 {
  &:before {
    content: $nt-contac-list-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-contac-list .path2 {
  &:before {
    content: $nt-contac-list-path2;
    margin-left: -1em;
    color: rgb(0, 199, 255);
  }
}
.nt-contact-backup .path1 {
  &:before {
    content: $nt-contact-backup-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-contact-backup .path2 {
  &:before {
    content: $nt-contact-backup-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-contact-phone .path1 {
  &:before {
    content: $nt-contact-phone-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-contact-phone .path2 {
  &:before {
    content: $nt-contact-phone-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-convergence-tvinternet .path1 {
  &:before {
    content: $nt-convergence-tvinternet-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-convergence-tvinternet .path2 {
  &:before {
    content: $nt-convergence-tvinternet-path2;
    margin-left: -2em;
    color: rgb(0, 200, 255);
  }
}
.nt-convergence-tvinternet .path3 {
  &:before {
    content: $nt-convergence-tvinternet-path3;
    margin-left: -2em;
    color: rgb(0, 200, 255);
    opacity: 0.5;
  }
}
.nt-convergence .path1 {
  &:before {
    content: $nt-convergence-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-convergence .path2 {
  &:before {
    content: $nt-convergence-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-corporativenoc .path1 {
  &:before {
    content: $nt-corporativenoc-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-corporativenoc .path2 {
  &:before {
    content: $nt-corporativenoc-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-Credentialbadge .path1 {
  &:before {
    content: $nt-Credentialbadge-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-Credentialbadge .path2 {
  &:before {
    content: $nt-Credentialbadge-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-customer-service .path1 {
  &:before {
    content: $nt-customer-service-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-customer-service .path2 {
  &:before {
    content: $nt-customer-service-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-datacenters-colocation .path1 {
  &:before {
    content: $nt-datacenters-colocation-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-datacenters-colocation .path2 {
  &:before {
    content: $nt-datacenters-colocation-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-dedicatedinternet .path1 {
  &:before {
    content: $nt-dedicatedinternet-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-dedicatedinternet .path2 {
  &:before {
    content: $nt-dedicatedinternet-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-delivery .path1 {
  &:before {
    content: $nt-delivery-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-delivery .path2 {
  &:before {
    content: $nt-delivery-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-dial {
  &:before {
    content: $nt-dial;
    color: #fff;
  }
}
.nt-discount-alternate .path1 {
  &:before {
    content: $nt-discount-alternate-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-discount-alternate .path2 {
  &:before {
    content: $nt-discount-alternate-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-discount .path1 {
  &:before {
    content: $nt-discount-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-discount .path2 {
  &:before {
    content: $nt-discount-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-dislike .path1 {
  &:before {
    content: $nt-dislike-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-dislike .path2 {
  &:before {
    content: $nt-dislike-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-dmzmodem .path1 {
  &:before {
    content: $nt-dmzmodem-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-dmzmodem .path2 {
  &:before {
    content: $nt-dmzmodem-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-download .path1 {
  &:before {
    content: $nt-download-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-download .path2 {
  &:before {
    content: $nt-download-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-edit .path1 {
  &:before {
    content: $nt-edit-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-edit .path2 {
  &:before {
    content: $nt-edit-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-error {
  &:before {
    content: $nt-error;
    color: #fff;
  }
}
.nt-expand-more {
  &:before {
    content: $nt-expand-more;
    color: #00c8ff;
  }
}
.nt-family-plan .path1 {
  &:before {
    content: $nt-family-plan-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-family-plan .path2 {
  &:before {
    content: $nt-family-plan-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-faq {
  &:before {
    content: $nt-faq;
    color: #fff;
  }
}
.nt-file-interactive .path1 {
  &:before {
    content: $nt-file-interactive-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-file-interactive .path2 {
  &:before {
    content: $nt-file-interactive-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-file-list .path1 {
  &:before {
    content: $nt-file-list-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-file-list .path2 {
  &:before {
    content: $nt-file-list-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-file-listok .path1 {
  &:before {
    content: $nt-file-listok-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-file-listok .path2 {
  &:before {
    content: $nt-file-listok-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-file-refresh .path1 {
  &:before {
    content: $nt-file-refresh-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-file-refresh .path2 {
  &:before {
    content: $nt-file-refresh-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-file-time .path1 {
  &:before {
    content: $nt-file-time-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-file-time .path2 {
  &:before {
    content: $nt-file-time-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-file .path1 {
  &:before {
    content: $nt-file-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-file .path2 {
  &:before {
    content: $nt-file-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-filedhcp .path1 {
  &:before {
    content: $nt-filedhcp-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-filedhcp .path2 {
  &:before {
    content: $nt-filedhcp-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-firewall .path1 {
  &:before {
    content: $nt-firewall-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-firewall .path2 {
  &:before {
    content: $nt-firewall-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-fixed-line .path1 {
  &:before {
    content: $nt-fixed-line-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-fixed-line .path2 {
  &:before {
    content: $nt-fixed-line-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-friends .path1 {
  &:before {
    content: $nt-friends-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-friends .path2 {
  &:before {
    content: $nt-friends-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-geolocation-small .path1 {
  &:before {
    content: $nt-geolocation-small-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-geolocation-small .path2 {
  &:before {
    content: $nt-geolocation-small-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-geolocation .path1 {
  &:before {
    content: $nt-geolocation-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-geolocation .path2 {
  &:before {
    content: $nt-geolocation-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-go .path1 {
  &:before {
    content: $nt-go-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-go .path2 {
  &:before {
    content: $nt-go-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-googleapps .path1 {
  &:before {
    content: $nt-googleapps-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-googleapps .path2 {
  &:before {
    content: $nt-googleapps-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-guestnetwork .path1 {
  &:before {
    content: $nt-guestnetwork-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-guestnetwork .path2 {
  &:before {
    content: $nt-guestnetwork-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-hand-up .path1 {
  &:before {
    content: $nt-hand-up-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-hand-up .path2 {
  &:before {
    content: $nt-hand-up-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-help-outline .path1 {
  &:before {
    content: $nt-help-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-help-outline .path2 {
  &:before {
    content: $nt-help-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-home {
  &:before {
    content: $nt-home;
    color: #fff;
  }
}
.nt-hpdcloud .path1 {
  &:before {
    content: $nt-hpdcloud-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-hpdcloud .path2 {
  &:before {
    content: $nt-hpdcloud-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-idea-light .path1 {
  &:before {
    content: $nt-idea-light-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-idea-light .path2 {
  &:before {
    content: $nt-idea-light-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-imei .path1 {
  &:before {
    content: $nt-imei-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-imei .path2 {
  &:before {
    content: $nt-imei-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-incoming-call .path1 {
  &:before {
    content: $nt-incoming-call-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-incoming-call .path2 {
  &:before {
    content: $nt-incoming-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-incoming-outgoing-call-B .path1 {
  &:before {
    content: $nt-incoming-outgoing-call-B-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-incoming-outgoing-call-B .path2 {
  &:before {
    content: $nt-incoming-outgoing-call-B-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-incoming-outgoing-call .path1 {
  &:before {
    content: $nt-incoming-outgoing-call-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-incoming-outgoing-call .path2 {
  &:before {
    content: $nt-incoming-outgoing-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-info-full .path1 {
  &:before {
    content: $nt-info-full-path1;
    color: rgb(0, 199, 255);
  }
}
.nt-info-full .path2 {
  &:before {
    content: $nt-info-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-info {
  &:before {
    content: $nt-info;
    color: #fff;
  }
}
.nt-internet-connection {
  &:before {
    content: $nt-internet-connection;
    color: #fff;
  }
}
.nt-internet-outline .path1 {
  &:before {
    content: $nt-internet-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-internet-outline .path2 {
  &:before {
    content: $nt-internet-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-internet-security .path1 {
  &:before {
    content: $nt-internet-security-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-internet-security .path2 {
  &:before {
    content: $nt-internet-security-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-internet .path1 {
  &:before {
    content: $nt-internet-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-internet .path2 {
  &:before {
    content: $nt-internet-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-invite {
  &:before {
    content: $nt-invite;
    color: #fff;
  }
}
.nt-ip-calling .path1 {
  &:before {
    content: $nt-ip-calling-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-ip-calling .path2 {
  &:before {
    content: $nt-ip-calling-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-key-money .path1 {
  &:before {
    content: $nt-key-money-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-key-money .path2 {
  &:before {
    content: $nt-key-money-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-key-number .path1 {
  &:before {
    content: $nt-key-number-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-key-number .path2 {
  &:before {
    content: $nt-key-number-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-landline .path1 {
  &:before {
    content: $nt-landline-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-landline .path2 {
  &:before {
    content: $nt-landline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-lend-me .path1 {
  &:before {
    content: $nt-lend-me-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-lend-me .path2 {
  &:before {
    content: $nt-lend-me-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-less {
  &:before {
    content: $nt-less;
    color: #fff;
  }
}
.nt-line-800 .path1 {
  &:before {
    content: $nt-line-800-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-line-800 .path2 {
  &:before {
    content: $nt-line-800-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-linkdata .path1 {
  &:before {
    content: $nt-linkdata-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-linkdata .path2 {
  &:before {
    content: $nt-linkdata-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-list .path1 {
  &:before {
    content: $nt-list-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-list .path2 {
  &:before {
    content: $nt-list-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-local-remitances .path1 {
  &:before {
    content: $nt-local-remitances-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-local-remitances .path2 {
  &:before {
    content: $nt-local-remitances-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-location-ruler .path1 {
  &:before {
    content: $nt-location-ruler-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-location-ruler .path2 {
  &:before {
    content: $nt-location-ruler-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-long-distance-calls .path1 {
  &:before {
    content: $nt-long-distance-calls-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-long-distance-calls .path2 {
  &:before {
    content: $nt-long-distance-calls-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-m2m-machinetomachine .path1 {
  &:before {
    content: $nt-m2m-machinetomachine-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-m2m-machinetomachine .path2 {
  &:before {
    content: $nt-m2m-machinetomachine-path2;
    margin-left: -2em;
    color: rgb(0, 200, 255);
  }
}
.nt-mail-server .path1 {
  &:before {
    content: $nt-mail-server-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-mail-server .path2 {
  &:before {
    content: $nt-mail-server-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-menu-more {
  &:before {
    content: $nt-menu-more;
    color: #fff;
  }
}
.nt-menu {
  &:before {
    content: $nt-menu;
    color: #fff;
  }
}
.nt-message-read .path1 {
  &:before {
    content: $nt-message-read-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-message-read .path2 {
  &:before {
    content: $nt-message-read-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-mobile-balance01 {
  &:before {
    content: $nt-mobile-balance01;
    color: #fff;
  }
}
.nt-mobile-balance02 {
  &:before {
    content: $nt-mobile-balance02;
    color: #fff;
  }
}
.nt-mobile-data-large {
  &:before {
    content: $nt-mobile-data-large;
    color: #fff;
  }
}
.nt-mobile-data .path1 {
  &:before {
    content: $nt-mobile-data-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-mobile-data .path2 {
  &:before {
    content: $nt-mobile-data-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-mobile-extrabalance .path1 {
  &:before {
    content: $nt-mobile-extrabalance-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-mobile-extrabalance .path2 {
  &:before {
    content: $nt-mobile-extrabalance-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-mobile-outline .path1 {
  &:before {
    content: $nt-mobile-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-mobile-outline .path2 {
  &:before {
    content: $nt-mobile-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-mobile-phone {
  &:before {
    content: $nt-mobile-phone;
    color: #fff;
  }
}
.nt-mobile-promotionalbalance .path1 {
  &:before {
    content: $nt-mobile-promotionalbalance-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-mobile-promotionalbalance .path2 {
  &:before {
    content: $nt-mobile-promotionalbalance-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-mobile-wifi .path1 {
  &:before {
    content: $nt-mobile-wifi-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-mobile-wifi .path2 {
  &:before {
    content: $nt-mobile-wifi-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-mobile .path1 {
  &:before {
    content: $nt-mobile-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-mobile .path2 {
  &:before {
    content: $nt-mobile-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-mobileinternet .path1 {
  &:before {
    content: $nt-mobileinternet-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-mobileinternet .path2 {
  &:before {
    content: $nt-mobileinternet-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-more-calls {
  &:before {
    content: $nt-more-calls;
    color: #fff;
  }
}
.nt-more {
  &:before {
    content: $nt-more;
    color: #fff;
  }
}
.nt-multipledevices .path1 {
  &:before {
    content: $nt-multipledevices-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-multipledevices .path2 {
  &:before {
    content: $nt-multipledevices-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-multipledevices .path3 {
  &:before {
    content: $nt-multipledevices-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-natmodem .path1 {
  &:before {
    content: $nt-natmodem-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-natmodem .path2 {
  &:before {
    content: $nt-natmodem-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-networkmonitoring .path1 {
  &:before {
    content: $nt-networkmonitoring-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-networkmonitoring .path2 {
  &:before {
    content: $nt-networkmonitoring-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-notebook-calendar .path1 {
  &:before {
    content: $nt-notebook-calendar-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-notebook-calendar .path2 {
  &:before {
    content: $nt-notebook-calendar-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-notebook-connectivity .path1 {
  &:before {
    content: $nt-notebook-connectivity-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-notebook-connectivity .path2 {
  &:before {
    content: $nt-notebook-connectivity-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-notebook-ip .path1 {
  &:before {
    content: $nt-notebook-ip-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-notebook-ip .path2 {
  &:before {
    content: $nt-notebook-ip-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-notebook-lanconnectivity .path1 {
  &:before {
    content: $nt-notebook-lanconnectivity-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-notebook-lanconnectivity .path2 {
  &:before {
    content: $nt-notebook-lanconnectivity-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-notebook-security .path1 {
  &:before {
    content: $nt-notebook-security-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-notebook-security .path2 {
  &:before {
    content: $nt-notebook-security-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-notebook-share .path1 {
  &:before {
    content: $nt-notebook-share-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-notebook-share .path2 {
  &:before {
    content: $nt-notebook-share-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-notebook .path1 {
  &:before {
    content: $nt-notebook-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-notebook .path2 {
  &:before {
    content: $nt-notebook-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-notification {
  &:before {
    content: $nt-notification;
    color: #fff;
  }
}
.nt-office365 .path1 {
  &:before {
    content: $nt-office365-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-office365 .path2 {
  &:before {
    content: $nt-office365-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-ok .path1 {
  &:before {
    content: $nt-ok-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-ok .path2 {
  &:before {
    content: $nt-ok-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-online-billing .path1 {
  &:before {
    content: $nt-online-billing-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-online-billing .path2 {
  &:before {
    content: $nt-online-billing-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-open-security .path1 {
  &:before {
    content: $nt-open-security-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-open-security .path2 {
  &:before {
    content: $nt-open-security-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-open-wifi .path1 {
  &:before {
    content: $nt-open-wifi-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-open-wifi .path2 {
  &:before {
    content: $nt-open-wifi-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-order .path1 {
  &:before {
    content: $nt-order-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-order .path2 {
  &:before {
    content: $nt-order-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-package-delivery .path1 {
  &:before {
    content: $nt-package-delivery-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-package-delivery .path2 {
  &:before {
    content: $nt-package-delivery-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-padlock .path1 {
  &:before {
    content: $nt-padlock-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-padlock .path2 {
  &:before {
    content: $nt-padlock-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-paquetigos .path1 {
  &:before {
    content: $nt-paquetigos-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-paquetigos .path2 {
  &:before {
    content: $nt-paquetigos-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-parentalcontrol .path1 {
  &:before {
    content: $nt-parentalcontrol-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-parentalcontrol .path2 {
  &:before {
    content: $nt-parentalcontrol-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-password-lock .path1 {
  &:before {
    content: $nt-password-lock-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-password-lock .path2 {
  &:before {
    content: $nt-password-lock-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-passwordok-lockconfirmed .path1 {
  &:before {
    content: $nt-passwordok-lockconfirmed-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-passwordok-lockconfirmed .path2 {
  &:before {
    content: $nt-passwordok-lockconfirmed-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-paused-call .path1 {
  &:before {
    content: $nt-paused-call-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-paused-call .path2 {
  &:before {
    content: $nt-paused-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-pay-merch .path1 {
  &:before {
    content: $nt-pay-merch-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-pay-merch .path2 {
  &:before {
    content: $nt-pay-merch-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-payment .path1 {
  &:before {
    content: $nt-payment-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-payment .path2 {
  &:before {
    content: $nt-payment-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-pdf .path1 {
  &:before {
    content: $nt-pdf-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-pdf .path2 {
  &:before {
    content: $nt-pdf-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-phone-history .path1 {
  &:before {
    content: $nt-phone-history-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-phone-history .path2 {
  &:before {
    content: $nt-phone-history-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-phone-settings .path1 {
  &:before {
    content: $nt-phone-settings-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-phone-settings .path2 {
  &:before {
    content: $nt-phone-settings-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-picture .path1 {
  &:before {
    content: $nt-picture-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-picture .path2 {
  &:before {
    content: $nt-picture-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-pin-code {
  &:before {
    content: $nt-pin-code;
    color: #fff;
  }
}
.nt-placement .path1 {
  &:before {
    content: $nt-placement-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-placement .path2 {
  &:before {
    content: $nt-placement-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-plan-tvinternet-telephony .path1 {
  &:before {
    content: $nt-plan-tvinternet-telephony-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-plan-tvinternet-telephony .path2 {
  &:before {
    content: $nt-plan-tvinternet-telephony-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-plan-tvinternet .path1 {
  &:before {
    content: $nt-plan-tvinternet-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-plan-tvinternet .path2 {
  &:before {
    content: $nt-plan-tvinternet-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-play-pause .path1 {
  &:before {
    content: $nt-play-pause-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-play-pause .path2 {
  &:before {
    content: $nt-play-pause-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-pluscall .path1 {
  &:before {
    content: $nt-pluscall-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-pluscall .path2 {
  &:before {
    content: $nt-pluscall-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-plusinternet {
  &:before {
    content: $nt-plusinternet;
    color: #00c8ff;
  }
}
.nt-police .path1 {
  &:before {
    content: $nt-police-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-police .path2 {
  &:before {
    content: $nt-police-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-pos-posnet .path1 {
  &:before {
    content: $nt-pos-posnet-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-pos-posnet .path2 {
  &:before {
    content: $nt-pos-posnet-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-premium-plan .path1 {
  &:before {
    content: $nt-premium-plan-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-premium-plan .path2 {
  &:before {
    content: $nt-premium-plan-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-prioritydevice .path1 {
  &:before {
    content: $nt-prioritydevice-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-prioritydevice .path2 {
  &:before {
    content: $nt-prioritydevice-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-privatenetwork .path1 {
  &:before {
    content: $nt-privatenetwork-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-privatenetwork .path2 {
  &:before {
    content: $nt-privatenetwork-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-profilenotebook-user .path1 {
  &:before {
    content: $nt-profilenotebook-user-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-profilenotebook-user .path2 {
  &:before {
    content: $nt-profilenotebook-user-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-ptt-presstospeak .path1 {
  &:before {
    content: $nt-ptt-presstospeak-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-ptt-presstospeak .path2 {
  &:before {
    content: $nt-ptt-presstospeak-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-qosmodem .path1 {
  &:before {
    content: $nt-qosmodem-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-qosmodem .path2 {
  &:before {
    content: $nt-qosmodem-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-rec .path1 {
  &:before {
    content: $nt-rec-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-rec .path2 {
  &:before {
    content: $nt-rec-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-receive-banktranfer .path1 {
  &:before {
    content: $nt-receive-banktranfer-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-receive-banktranfer .path2 {
  &:before {
    content: $nt-receive-banktranfer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-red .path1 {
  &:before {
    content: $nt-red-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-red .path2 {
  &:before {
    content: $nt-red-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-registry .path1 {
  &:before {
    content: $nt-registry-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-registry .path2 {
  &:before {
    content: $nt-registry-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-report .path1 {
  &:before {
    content: $nt-report-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-report .path2 {
  &:before {
    content: $nt-report-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-roaming-large {
  &:before {
    content: $nt-roaming-large;
    color: #fff;
  }
}
.nt-roaming .path1 {
  &:before {
    content: $nt-roaming-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-roaming .path2 {
  &:before {
    content: $nt-roaming-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-row-bottom {
  &:before {
    content: $nt-row-bottom;
    color: #fff;
  }
}
.nt-row-left {
  &:before {
    content: $nt-row-left;
    color: #fff;
  }
}
.nt-row-rigth {
  &:before {
    content: $nt-row-rigth;
    color: #fff;
  }
}
.nt-row-top {
  &:before {
    content: $nt-row-top;
    color: #fff;
  }
}
.nt-saas .path1 {
  &:before {
    content: $nt-saas-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-saas .path2 {
  &:before {
    content: $nt-saas-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-safetoschool .path1 {
  &:before {
    content: $nt-safetoschool-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-safetoschool .path2 {
  &:before {
    content: $nt-safetoschool-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-sd .path1 {
  &:before {
    content: $nt-sd-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-sd .path2 {
  &:before {
    content: $nt-sd-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-search-contact .path1 {
  &:before {
    content: $nt-search-contact-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-search-contact .path2 {
  &:before {
    content: $nt-search-contact-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-search {
  &:before {
    content: $nt-search;
    color: #fff;
  }
}
.nt-secure-pay {
  &:before {
    content: $nt-secure-pay;
    color: #fff;
  }
}
.nt-security-call .path1 {
  &:before {
    content: $nt-security-call-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-security-call .path2 {
  &:before {
    content: $nt-security-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-security-list .path1 {
  &:before {
    content: $nt-security-list-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-security-list .path2 {
  &:before {
    content: $nt-security-list-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-security-pending .path1 {
  &:before {
    content: $nt-security-pending-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-security-pending .path2 {
  &:before {
    content: $nt-security-pending-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-security-telephony .path1 {
  &:before {
    content: $nt-security-telephony-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-security-telephony .path2 {
  &:before {
    content: $nt-security-telephony-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-security-wifi .path1 {
  &:before {
    content: $nt-security-wifi-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-security-wifi .path2 {
  &:before {
    content: $nt-security-wifi-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-security .path1 {
  &:before {
    content: $nt-security-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-security .path2 {
  &:before {
    content: $nt-security-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-send-banktransfer .path1 {
  &:before {
    content: $nt-send-banktransfer-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-send-banktransfer .path2 {
  &:before {
    content: $nt-send-banktransfer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-send-top-up .path1 {
  &:before {
    content: $nt-send-top-up-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-send-top-up .path2 {
  &:before {
    content: $nt-send-top-up-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-server-checkok .path1 {
  &:before {
    content: $nt-server-checkok-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-server-checkok .path2 {
  &:before {
    content: $nt-server-checkok-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-server-list .path1 {
  &:before {
    content: $nt-server-list-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-server-list .path2 {
  &:before {
    content: $nt-server-list-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-server-preferences .path1 {
  &:before {
    content: $nt-server-preferences-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-server-preferences .path2 {
  &:before {
    content: $nt-server-preferences-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-server-settings .path1 {
  &:before {
    content: $nt-server-settings-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-server-settings .path2 {
  &:before {
    content: $nt-server-settings-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-server-user .path1 {
  &:before {
    content: $nt-server-user-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-server-user .path2 {
  &:before {
    content: $nt-server-user-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-server .path1 {
  &:before {
    content: $nt-server-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-server .path2 {
  &:before {
    content: $nt-server-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-servers .path1 {
  &:before {
    content: $nt-servers-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-servers .path2 {
  &:before {
    content: $nt-servers-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-service-diagnostic .path1 {
  &:before {
    content: $nt-service-diagnostic-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-service-diagnostic .path2 {
  &:before {
    content: $nt-service-diagnostic-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-services .path1 {
  &:before {
    content: $nt-services-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-services .path2 {
  &:before {
    content: $nt-services-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-settings {
  &:before {
    content: $nt-settings;
    color: #fff;
  }
}
.nt-shaking-hands .path1 {
  &:before {
    content: $nt-shaking-hands-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-shaking-hands .path2 {
  &:before {
    content: $nt-shaking-hands-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-shopping-cart-2 {
  &:before {
    content: $nt-shopping-cart-2;
    color: #fff;
  }
}
.nt-shopping-cart .path1 {
  &:before {
    content: $nt-shopping-cart-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-shopping-cart .path2 {
  &:before {
    content: $nt-shopping-cart-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-signal-location .path1 {
  &:before {
    content: $nt-signal-location-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-signal-location .path2 {
  &:before {
    content: $nt-signal-location-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-signal .path1 {
  &:before {
    content: $nt-signal-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-signal .path2 {
  &:before {
    content: $nt-signal-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-sms-large {
  &:before {
    content: $nt-sms-large;
    color: #fff;
  }
}
.nt-sms .path1 {
  &:before {
    content: $nt-sms-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-sms .path2 {
  &:before {
    content: $nt-sms-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-special-services .path1 {
  &:before {
    content: $nt-special-services-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-special-services .path2 {
  &:before {
    content: $nt-special-services-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-specs .path1 {
  &:before {
    content: $nt-specs-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-specs .path2 {
  &:before {
    content: $nt-specs-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-speedcalculator-timer .path1 {
  &:before {
    content: $nt-speedcalculator-timer-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-speedcalculator-timer .path2 {
  &:before {
    content: $nt-speedcalculator-timer-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-star-circle .path1 {
  &:before {
    content: $nt-star-circle-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-star-circle .path2 {
  &:before {
    content: $nt-star-circle-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-star {
  &:before {
    content: $nt-star;
    color: #fff;
  }
}
.nt-statistics .path1 {
  &:before {
    content: $nt-statistics-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-statistics .path2 {
  &:before {
    content: $nt-statistics-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-store .path1 {
  &:before {
    content: $nt-store-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-store .path2 {
  &:before {
    content: $nt-store-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-subscriptions .path1 {
  &:before {
    content: $nt-subscriptions-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-subscriptions .path2 {
  &:before {
    content: $nt-subscriptions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-target-server .path1 {
  &:before {
    content: $nt-target-server-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-target-server .path2 {
  &:before {
    content: $nt-target-server-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-television-full .path1 {
  &:before {
    content: $nt-television-full-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-television-full .path2 {
  &:before {
    content: $nt-television-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-television-outline .path1 {
  &:before {
    content: $nt-television-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-television-outline .path2 {
  &:before {
    content: $nt-television-outline-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-television {
  &:before {
    content: $nt-television;
    color: #fff;
  }
}
.nt-ticket-GO .path1 {
  &:before {
    content: $nt-ticket-GO-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-ticket-GO .path2 {
  &:before {
    content: $nt-ticket-GO-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-ticket-money .path1 {
  &:before {
    content: $nt-ticket-money-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-ticket-money .path2 {
  &:before {
    content: $nt-ticket-money-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-ticketinformation .path1 {
  &:before {
    content: $nt-ticketinformation-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-ticketinformation .path2 {
  &:before {
    content: $nt-ticketinformation-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tigo-backup .path1 {
  &:before {
    content: $nt-tigo-backup-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-tigo-backup .path2 {
  &:before {
    content: $nt-tigo-backup-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-tigo-online .path1 {
  &:before {
    content: $nt-tigo-online-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-tigo-online .path2 {
  &:before {
    content: $nt-tigo-online-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-timer .path1 {
  &:before {
    content: $nt-timer-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-timer .path2 {
  &:before {
    content: $nt-timer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-top-up .path1 {
  &:before {
    content: $nt-top-up-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-top-up .path2 {
  &:before {
    content: $nt-top-up-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-triple-play .path1 {
  &:before {
    content: $nt-triple-play-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-triple-play .path2 {
  &:before {
    content: $nt-triple-play-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-3d .path1 {
  &:before {
    content: $nt-tv-3d-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-3d .path2 {
  &:before {
    content: $nt-tv-3d-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-cable {
  &:before {
    content: $nt-tv-cable;
    color: #fff;
  }
}
.nt-tv-decoder .path1 {
  &:before {
    content: $nt-tv-decoder-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-decoder .path2 {
  &:before {
    content: $nt-tv-decoder-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-digital .path1 {
  &:before {
    content: $nt-tv-digital-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-digital .path2 {
  &:before {
    content: $nt-tv-digital-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-favoriteticket .path1 {
  &:before {
    content: $nt-tv-favoriteticket-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-favoriteticket .path2 {
  &:before {
    content: $nt-tv-favoriteticket-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-guide .path1 {
  &:before {
    content: $nt-tv-guide-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-guide .path2 {
  &:before {
    content: $nt-tv-guide-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-hd .path1 {
  &:before {
    content: $nt-tv-hd-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-hd .path2 {
  &:before {
    content: $nt-tv-hd-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-list .path1 {
  &:before {
    content: $nt-tv-list-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-list .path2 {
  &:before {
    content: $nt-tv-list-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-live .path1 {
  &:before {
    content: $nt-tv-live-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-live .path2 {
  &:before {
    content: $nt-tv-live-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-movies {
  &:before {
    content: $nt-tv-movies;
    color: #fff;
  }
}
.nt-tv-multiroom .path1 {
  &:before {
    content: $nt-tv-multiroom-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-multiroom .path2 {
  &:before {
    content: $nt-tv-multiroom-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-recommender .path1 {
  &:before {
    content: $nt-tv-recommender-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-recommender .path2 {
  &:before {
    content: $nt-tv-recommender-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-remotecontrol .path1 {
  &:before {
    content: $nt-tv-remotecontrol-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-remotecontrol .path2 {
  &:before {
    content: $nt-tv-remotecontrol-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-satellite .path1 {
  &:before {
    content: $nt-tv-satellite-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-satellite .path2 {
  &:before {
    content: $nt-tv-satellite-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-sofa .path1 {
  &:before {
    content: $nt-tv-sofa-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-sofa .path2 {
  &:before {
    content: $nt-tv-sofa-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-ticketvideo .path1 {
  &:before {
    content: $nt-tv-ticketvideo-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-ticketvideo .path2 {
  &:before {
    content: $nt-tv-ticketvideo-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-tigoplay .path1 {
  &:before {
    content: $nt-tv-tigoplay-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-tigoplay .path2 {
  &:before {
    content: $nt-tv-tigoplay-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-videochannels .path1 {
  &:before {
    content: $nt-tv-videochannels-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-videochannels .path2 {
  &:before {
    content: $nt-tv-videochannels-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-tv-vod .path1 {
  &:before {
    content: $nt-tv-vod-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-tv-vod .path2 {
  &:before {
    content: $nt-tv-vod-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-unlimited-calls .path1 {
  &:before {
    content: $nt-unlimited-calls-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-unlimited-calls .path2 {
  &:before {
    content: $nt-unlimited-calls-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-unlimitedshippingpackages .path1 {
  &:before {
    content: $nt-unlimitedshippingpackages-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-unlimitedshippingpackages .path2 {
  &:before {
    content: $nt-unlimitedshippingpackages-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-user-chat .path1 {
  &:before {
    content: $nt-user-chat-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-user-chat .path2 {
  &:before {
    content: $nt-user-chat-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-user-exit .path1 {
  &:before {
    content: $nt-user-exit-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-user-exit .path2 {
  &:before {
    content: $nt-user-exit-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-user-invalid .path1 {
  &:before {
    content: $nt-user-invalid-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-user-invalid .path2 {
  &:before {
    content: $nt-user-invalid-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-user-logout .path1 {
  &:before {
    content: $nt-user-logout-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-user-logout .path2 {
  &:before {
    content: $nt-user-logout-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-user-profile {
  &:before {
    content: $nt-user-profile;
    color: #fff;
  }
}
.nt-user-protection .path1 {
  &:before {
    content: $nt-user-protection-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-user-protection .path2 {
  &:before {
    content: $nt-user-protection-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-user-question .path1 {
  &:before {
    content: $nt-user-question-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-user-question .path2 {
  &:before {
    content: $nt-user-question-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-user-valid .path1 {
  &:before {
    content: $nt-user-valid-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-user-valid .path2 {
  &:before {
    content: $nt-user-valid-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-user {
  &:before {
    content: $nt-user;
    color: #fff;
  }
}
.nt-users .path1 {
  &:before {
    content: $nt-users-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-users .path2 {
  &:before {
    content: $nt-users-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-velocity-speed .path1 {
  &:before {
    content: $nt-velocity-speed-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-velocity-speed .path2 {
  &:before {
    content: $nt-velocity-speed-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-video-surveillance .path1 {
  &:before {
    content: $nt-video-surveillance-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-video-surveillance .path2 {
  &:before {
    content: $nt-video-surveillance-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-virtual-servers .path1 {
  &:before {
    content: $nt-virtual-servers-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-virtual-servers .path2 {
  &:before {
    content: $nt-virtual-servers-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-voicemail .path1 {
  &:before {
    content: $nt-voicemail-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-voicemail .path2 {
  &:before {
    content: $nt-voicemail-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-volume .path1 {
  &:before {
    content: $nt-volume-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-volume .path2 {
  &:before {
    content: $nt-volume-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-waiting-call .path1 {
  &:before {
    content: $nt-waiting-call-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-waiting-call .path2 {
  &:before {
    content: $nt-waiting-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-web-asset .path1 {
  &:before {
    content: $nt-web-asset-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-web-asset .path2 {
  &:before {
    content: $nt-web-asset-path2;
    margin-left: -1em;
    color: rgb(0, 199, 255);
  }
}
.nt-web-hosting .path1 {
  &:before {
    content: $nt-web-hosting-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-web-hosting .path2 {
  &:before {
    content: $nt-web-hosting-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-widgets .path1 {
  &:before {
    content: $nt-widgets-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-widgets .path2 {
  &:before {
    content: $nt-widgets-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-wifi-highsignal {
  &:before {
    content: $nt-wifi-highsignal;
    color: #fff;
  }
}
.nt-wifi-lowsignal .path1 {
  &:before {
    content: $nt-wifi-lowsignal-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-wifi-lowsignal .path2 {
  &:before {
    content: $nt-wifi-lowsignal-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-wifi-mediumsignal .path1 {
  &:before {
    content: $nt-wifi-mediumsignal-path1;
    color: rgb(0, 200, 255);
  }
}
.nt-wifi-mediumsignal .path2 {
  &:before {
    content: $nt-wifi-mediumsignal-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.nt-world-server .path1 {
  &:before {
    content: $nt-world-server-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-world-server .path2 {
  &:before {
    content: $nt-world-server-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.nt-xls .path1 {
  &:before {
    content: $nt-xls-path1;
    color: rgb(255, 255, 255);
  }
}
.nt-xls .path2 {
  &:before {
    content: $nt-xls-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-4g-large {
  &:before {
    content: $pt-4g-large;
    color: #00377b;
  }
}
.pt-4g .path1 {
  &:before {
    content: $pt-4g-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-4g .path2 {
  &:before {
    content: $pt-4g-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-account-status .path1 {
  &:before {
    content: $pt-account-status-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-account-status .path2 {
  &:before {
    content: $pt-account-status-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-add-balance .path1 {
  &:before {
    content: $pt-add-balance-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-add-balance .path2 {
  &:before {
    content: $pt-add-balance-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-add-card .path1 {
  &:before {
    content: $pt-add-card-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-add-card .path2 {
  &:before {
    content: $pt-add-card-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-add-channels .path1 {
  &:before {
    content: $pt-add-channels-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-add-channels .path2 {
  &:before {
    content: $pt-add-channels-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-add-user .path1 {
  &:before {
    content: $pt-add-user-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-add-user .path2 {
  &:before {
    content: $pt-add-user-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-add {
  &:before {
    content: $pt-add;
    color: #00377b;
  }
}
.pt-advanced-solutions-outline .path1 {
  &:before {
    content: $pt-advanced-solutions-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-advanced-solutions-outline .path2 {
  &:before {
    content: $pt-advanced-solutions-outline-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-advanced-solutions .path1 {
  &:before {
    content: $pt-advanced-solutions-path1;
    color: rgb(55, 200, 70);
  }
}
.pt-advanced-solutions .path2 {
  &:before {
    content: $pt-advanced-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.pt-agreement .path1 {
  &:before {
    content: $pt-agreement-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-agreement .path2 {
  &:before {
    content: $pt-agreement-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-analog-tv .path1 {
  &:before {
    content: $pt-analog-tv-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-analog-tv .path2 {
  &:before {
    content: $pt-analog-tv-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-apn .path1 {
  &:before {
    content: $pt-apn-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-apn .path2 {
  &:before {
    content: $pt-apn-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-app-premium .path1 {
  &:before {
    content: $pt-app-premium-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-app-premium .path2 {
  &:before {
    content: $pt-app-premium-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-app-store .path1 {
  &:before {
    content: $pt-app-store-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-app-store .path2 {
  &:before {
    content: $pt-app-store-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-apps-full .path1 {
  &:before {
    content: $pt-apps-full-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-apps-full .path2 {
  &:before {
    content: $pt-apps-full-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-apps-small {
  &:before {
    content: $pt-apps-small;
    color: #00377b;
  }
}
.pt-apps {
  &:before {
    content: $pt-apps;
    color: #00377d;
  }
}
.pt-at {
  &:before {
    content: $pt-at;
    color: #00377b;
  }
}
.pt-balance-large {
  &:before {
    content: $pt-balance-large;
    color: #00377b;
  }
}
.pt-balance .path1 {
  &:before {
    content: $pt-balance-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-balance .path2 {
  &:before {
    content: $pt-balance-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-banktransfer .path1 {
  &:before {
    content: $pt-banktransfer-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-banktransfer .path2 {
  &:before {
    content: $pt-banktransfer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-bigdata .path1 {
  &:before {
    content: $pt-bigdata-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-bigdata .path2 {
  &:before {
    content: $pt-bigdata-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-billing .path1 {
  &:before {
    content: $pt-billing-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-billing .path2 {
  &:before {
    content: $pt-billing-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-billpayment_1 .path1 {
  &:before {
    content: $pt-billpayment_1-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-billpayment_1 .path2 {
  &:before {
    content: $pt-billpayment_1-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-billpayment .path1 {
  &:before {
    content: $pt-billpayment-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-billpayment .path2 {
  &:before {
    content: $pt-billpayment-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-broadband-wifimodem .path1 {
  &:before {
    content: $pt-broadband-wifimodem-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-broadband-wifimodem .path2 {
  &:before {
    content: $pt-broadband-wifimodem-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-building .path1 {
  &:before {
    content: $pt-building-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-building .path2 {
  &:before {
    content: $pt-building-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-bundle .path1 {
  &:before {
    content: $pt-bundle-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-bundle .path2 {
  &:before {
    content: $pt-bundle-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-calendar-money .path1 {
  &:before {
    content: $pt-calendar-money-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-calendar-money .path2 {
  &:before {
    content: $pt-calendar-money-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-calendar-timer .path1 {
  &:before {
    content: $pt-calendar-timer-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-calendar-timer .path2 {
  &:before {
    content: $pt-calendar-timer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-calendar .path1 {
  &:before {
    content: $pt-calendar-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-calendar .path2 {
  &:before {
    content: $pt-calendar-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-call-rerouting .path1 {
  &:before {
    content: $pt-call-rerouting-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-call-rerouting .path2 {
  &:before {
    content: $pt-call-rerouting-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-call {
  &:before {
    content: $pt-call;
    color: #00377b;
  }
}
.pt-calls-large {
  &:before {
    content: $pt-calls-large;
    color: #00377b;
  }
}
.pt-calls .path1 {
  &:before {
    content: $pt-calls-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-calls .path2 {
  &:before {
    content: $pt-calls-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-cash .path1 {
  &:before {
    content: $pt-cash-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-cash .path2 {
  &:before {
    content: $pt-cash-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-check-hexagon .path1 {
  &:before {
    content: $pt-check-hexagon-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-check-hexagon .path2 {
  &:before {
    content: $pt-check-hexagon-path2;
    margin-left: -1em;
    color: rgb(0, 55, 125);
  }
}
.pt-check {
  &:before {
    content: $pt-check;
    color: #00377d;
  }
}
.pt-clear {
  &:before {
    content: $pt-clear;
    color: #00c7ff;
  }
}
.pt-clock .path1 {
  &:before {
    content: $pt-clock-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-clock .path2 {
  &:before {
    content: $pt-clock-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-closed-wifi .path1 {
  &:before {
    content: $pt-closed-wifi-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-closed-wifi .path2 {
  &:before {
    content: $pt-closed-wifi-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-cloud-solutions-outline .path1 {
  &:before {
    content: $pt-cloud-solutions-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-cloud-solutions-outline .path2 {
  &:before {
    content: $pt-cloud-solutions-outline-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-cloud-solutions .path1 {
  &:before {
    content: $pt-cloud-solutions-path1;
    color: rgb(237, 120, 30);
  }
}
.pt-cloud-solutions .path2 {
  &:before {
    content: $pt-cloud-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.pt-cloud {
  &:before {
    content: $pt-cloud;
    color: #00377b;
  }
}
.pt-cloudbackup .path1 {
  &:before {
    content: $pt-cloudbackup-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-cloudbackup .path2 {
  &:before {
    content: $pt-cloudbackup-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-cloudfirewall .path1 {
  &:before {
    content: $pt-cloudfirewall-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-cloudfirewall .path2 {
  &:before {
    content: $pt-cloudfirewall-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-code {
  &:before {
    content: $pt-code;
    color: #00377d;
  }
}
.pt-complaints .path1 {
  &:before {
    content: $pt-complaints-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-complaints .path2 {
  &:before {
    content: $pt-complaints-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-conference-call .path1 {
  &:before {
    content: $pt-conference-call-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-conference-call .path2 {
  &:before {
    content: $pt-conference-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-connectivity-01 .path1 {
  &:before {
    content: $pt-connectivity-01-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-connectivity-01 .path2 {
  &:before {
    content: $pt-connectivity-01-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-connectivity-solutions-outline .path1 {
  &:before {
    content: $pt-connectivity-solutions-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-connectivity-solutions-outline .path2 {
  &:before {
    content: $pt-connectivity-solutions-outline-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-connectivity-solutions .path1 {
  &:before {
    content: $pt-connectivity-solutions-path1;
    color: rgb(197, 30, 120);
  }
}
.pt-connectivity-solutions .path2 {
  &:before {
    content: $pt-connectivity-solutions-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.pt-connectivity .path1 {
  &:before {
    content: $pt-connectivity-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-connectivity .path2 {
  &:before {
    content: $pt-connectivity-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-contac-backup .path1 {
  &:before {
    content: $pt-contac-backup-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-contac-backup .path2 {
  &:before {
    content: $pt-contac-backup-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-contac-list .path1 {
  &:before {
    content: $pt-contac-list-path1;
    color: rgb(0, 55, 125);
  }
}
.pt-contac-list .path2 {
  &:before {
    content: $pt-contac-list-path2;
    margin-left: -1em;
    color: rgb(0, 199, 255);
  }
}
.pt-contact-backup .path1 {
  &:before {
    content: $pt-contact-backup-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-contact-backup .path2 {
  &:before {
    content: $pt-contact-backup-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-contact-phone .path1 {
  &:before {
    content: $pt-contact-phone-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-contact-phone .path2 {
  &:before {
    content: $pt-contact-phone-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-convergence-tvinternet .path1 {
  &:before {
    content: $pt-convergence-tvinternet-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-convergence-tvinternet .path2 {
  &:before {
    content: $pt-convergence-tvinternet-path2;
    margin-left: -2em;
    color: rgb(0, 200, 255);
  }
}
.pt-convergence-tvinternet .path3 {
  &:before {
    content: $pt-convergence-tvinternet-path3;
    margin-left: -2em;
    color: rgb(0, 200, 255);
    opacity: 0.5;
  }
}
.pt-convergence .path1 {
  &:before {
    content: $pt-convergence-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-convergence .path2 {
  &:before {
    content: $pt-convergence-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-corporativenoc .path1 {
  &:before {
    content: $pt-corporativenoc-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-corporativenoc .path2 {
  &:before {
    content: $pt-corporativenoc-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-Credentialbadge .path1 {
  &:before {
    content: $pt-Credentialbadge-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-Credentialbadge .path2 {
  &:before {
    content: $pt-Credentialbadge-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-customer-service .path1 {
  &:before {
    content: $pt-customer-service-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-customer-service .path2 {
  &:before {
    content: $pt-customer-service-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-datacenters-colocation .path1 {
  &:before {
    content: $pt-datacenters-colocation-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-datacenters-colocation .path2 {
  &:before {
    content: $pt-datacenters-colocation-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-dedicatedinternet .path1 {
  &:before {
    content: $pt-dedicatedinternet-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-dedicatedinternet .path2 {
  &:before {
    content: $pt-dedicatedinternet-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-delivery .path1 {
  &:before {
    content: $pt-delivery-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-delivery .path2 {
  &:before {
    content: $pt-delivery-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-dial {
  &:before {
    content: $pt-dial;
    color: #00377b;
  }
}
.pt-discount-alternate .path1 {
  &:before {
    content: $pt-discount-alternate-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-discount-alternate .path2 {
  &:before {
    content: $pt-discount-alternate-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-discount .path1 {
  &:before {
    content: $pt-discount-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-discount .path2 {
  &:before {
    content: $pt-discount-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-dislike .path1 {
  &:before {
    content: $pt-dislike-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-dislike .path2 {
  &:before {
    content: $pt-dislike-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-dmzmodem .path1 {
  &:before {
    content: $pt-dmzmodem-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-dmzmodem .path2 {
  &:before {
    content: $pt-dmzmodem-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-doublearrow-left .path1 {
  &:before {
    content: $pt-doublearrow-left-path1;
    color: rgb(0, 55, 125);
  }
}
.pt-doublearrow-left .path2 {
  &:before {
    content: $pt-doublearrow-left-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.pt-doublearrow-right .path1 {
  &:before {
    content: $pt-doublearrow-right-path1;
    color: rgb(0, 55, 125);
  }
}
.pt-doublearrow-right .path2 {
  &:before {
    content: $pt-doublearrow-right-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.pt-download .path1 {
  &:before {
    content: $pt-download-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-download .path2 {
  &:before {
    content: $pt-download-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-edit .path1 {
  &:before {
    content: $pt-edit-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-edit .path2 {
  &:before {
    content: $pt-edit-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-error-circle {
  &:before {
    content: $pt-error-circle;
    color: #00377d;
  }
}
.pt-error {
  &:before {
    content: $pt-error;
    color: #00377b;
  }
}
.pt-expand-more {
  &:before {
    content: $pt-expand-more;
    color: #00c8ff;
  }
}
.pt-family-plan .path1 {
  &:before {
    content: $pt-family-plan-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-family-plan .path2 {
  &:before {
    content: $pt-family-plan-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-faq {
  &:before {
    content: $pt-faq;
    color: #00377b;
  }
}
.pt-file-interactive .path1 {
  &:before {
    content: $pt-file-interactive-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-file-interactive .path2 {
  &:before {
    content: $pt-file-interactive-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-file-list .path1 {
  &:before {
    content: $pt-file-list-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-file-list .path2 {
  &:before {
    content: $pt-file-list-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-file-listok .path1 {
  &:before {
    content: $pt-file-listok-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-file-listok .path2 {
  &:before {
    content: $pt-file-listok-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-file-refresh .path1 {
  &:before {
    content: $pt-file-refresh-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-file-refresh .path2 {
  &:before {
    content: $pt-file-refresh-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-file-time .path1 {
  &:before {
    content: $pt-file-time-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-file-time .path2 {
  &:before {
    content: $pt-file-time-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-file .path1 {
  &:before {
    content: $pt-file-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-file .path2 {
  &:before {
    content: $pt-file-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-filedhcp .path1 {
  &:before {
    content: $pt-filedhcp-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-filedhcp .path2 {
  &:before {
    content: $pt-filedhcp-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-firewall .path1 {
  &:before {
    content: $pt-firewall-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-firewall .path2 {
  &:before {
    content: $pt-firewall-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-fixed-line .path1 {
  &:before {
    content: $pt-fixed-line-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-fixed-line .path2 {
  &:before {
    content: $pt-fixed-line-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-friends .path1 {
  &:before {
    content: $pt-friends-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-friends .path2 {
  &:before {
    content: $pt-friends-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-geolocation-small .path1 {
  &:before {
    content: $pt-geolocation-small-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-geolocation-small .path2 {
  &:before {
    content: $pt-geolocation-small-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-geolocation .path1 {
  &:before {
    content: $pt-geolocation-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-geolocation .path2 {
  &:before {
    content: $pt-geolocation-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-go .path1 {
  &:before {
    content: $pt-go-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-go .path2 {
  &:before {
    content: $pt-go-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-googleapps .path1 {
  &:before {
    content: $pt-googleapps-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-googleapps .path2 {
  &:before {
    content: $pt-googleapps-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-guestnetwork .path1 {
  &:before {
    content: $pt-guestnetwork-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-guestnetwork .path2 {
  &:before {
    content: $pt-guestnetwork-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-hand-up .path1 {
  &:before {
    content: $pt-hand-up-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-hand-up .path2 {
  &:before {
    content: $pt-hand-up-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-help-outline .path1 {
  &:before {
    content: $pt-help-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-help-outline .path2 {
  &:before {
    content: $pt-help-outline-path2;
    margin-left: -1em;
    color: rgb(0, 55, 125);
  }
}
.pt-home {
  &:before {
    content: $pt-home;
    color: #00377d;
  }
}
.pt-hpdcloud .path1 {
  &:before {
    content: $pt-hpdcloud-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-hpdcloud .path2 {
  &:before {
    content: $pt-hpdcloud-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-idea-light .path1 {
  &:before {
    content: $pt-idea-light-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-idea-light .path2 {
  &:before {
    content: $pt-idea-light-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-imei .path1 {
  &:before {
    content: $pt-imei-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-imei .path2 {
  &:before {
    content: $pt-imei-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-incoming-call .path1 {
  &:before {
    content: $pt-incoming-call-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-incoming-call .path2 {
  &:before {
    content: $pt-incoming-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-incoming-outgoing-call-B .path1 {
  &:before {
    content: $pt-incoming-outgoing-call-B-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-incoming-outgoing-call-B .path2 {
  &:before {
    content: $pt-incoming-outgoing-call-B-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-incoming-outgoing-call .path1 {
  &:before {
    content: $pt-incoming-outgoing-call-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-incoming-outgoing-call .path2 {
  &:before {
    content: $pt-incoming-outgoing-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-info-full .path1 {
  &:before {
    content: $pt-info-full-path1;
    color: rgb(0, 199, 255);
  }
}
.pt-info-full .path2 {
  &:before {
    content: $pt-info-full-path2;
    margin-left: -1em;
    color: rgb(0, 55, 125);
  }
}
.pt-info {
  &:before {
    content: $pt-info;
    color: #00377b;
  }
}
.pt-internet-connection {
  &:before {
    content: $pt-internet-connection;
    color: #00377b;
  }
}
.pt-internet-outline .path1 {
  &:before {
    content: $pt-internet-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-internet-outline .path2 {
  &:before {
    content: $pt-internet-outline-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-internet-security .path1 {
  &:before {
    content: $pt-internet-security-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-internet-security .path2 {
  &:before {
    content: $pt-internet-security-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-internet .path1 {
  &:before {
    content: $pt-internet-path1;
    color: rgb(237, 120, 30);
  }
}
.pt-internet .path2 {
  &:before {
    content: $pt-internet-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.pt-invite {
  &:before {
    content: $pt-invite;
    color: #00377b;
  }
}
.pt-ip-calling .path1 {
  &:before {
    content: $pt-ip-calling-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-ip-calling .path2 {
  &:before {
    content: $pt-ip-calling-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-key-money .path1 {
  &:before {
    content: $pt-key-money-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-key-money .path2 {
  &:before {
    content: $pt-key-money-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-key-number .path1 {
  &:before {
    content: $pt-key-number-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-key-number .path2 {
  &:before {
    content: $pt-key-number-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-landline .path1 {
  &:before {
    content: $pt-landline-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-landline .path2 {
  &:before {
    content: $pt-landline-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-lend-me .path1 {
  &:before {
    content: $pt-lend-me-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-lend-me .path2 {
  &:before {
    content: $pt-lend-me-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-less {
  &:before {
    content: $pt-less;
    color: #00377b;
  }
}
.pt-line-800 .path1 {
  &:before {
    content: $pt-line-800-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-line-800 .path2 {
  &:before {
    content: $pt-line-800-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-linkdata .path1 {
  &:before {
    content: $pt-linkdata-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-linkdata .path2 {
  &:before {
    content: $pt-linkdata-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-list .path1 {
  &:before {
    content: $pt-list-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-list .path2 {
  &:before {
    content: $pt-list-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-local-remitances .path1 {
  &:before {
    content: $pt-local-remitances-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-local-remitances .path2 {
  &:before {
    content: $pt-local-remitances-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-location-ruler .path1 {
  &:before {
    content: $pt-location-ruler-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-location-ruler .path2 {
  &:before {
    content: $pt-location-ruler-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-long-distance-calls .path1 {
  &:before {
    content: $pt-long-distance-calls-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-long-distance-calls .path2 {
  &:before {
    content: $pt-long-distance-calls-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-m2m-machinetomachine .path1 {
  &:before {
    content: $pt-m2m-machinetomachine-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-m2m-machinetomachine .path2 {
  &:before {
    content: $pt-m2m-machinetomachine-path2;
    margin-left: -2em;
    color: rgb(0, 200, 255);
  }
}
.pt-mail-server .path1 {
  &:before {
    content: $pt-mail-server-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-mail-server .path2 {
  &:before {
    content: $pt-mail-server-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-menu-more {
  &:before {
    content: $pt-menu-more;
    color: #00377b;
  }
}
.pt-menu {
  &:before {
    content: $pt-menu;
    color: #00377d;
  }
}
.pt-message-read .path1 {
  &:before {
    content: $pt-message-read-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-message-read .path2 {
  &:before {
    content: $pt-message-read-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-mobile-balance01 {
  &:before {
    content: $pt-mobile-balance01;
    color: #00377b;
  }
}
.pt-mobile-balance02 {
  &:before {
    content: $pt-mobile-balance02;
    color: #00377b;
  }
}
.pt-mobile-data-large {
  &:before {
    content: $pt-mobile-data-large;
    color: #00377b;
  }
}
.pt-mobile-data .path1 {
  &:before {
    content: $pt-mobile-data-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-mobile-data .path2 {
  &:before {
    content: $pt-mobile-data-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-mobile-extrabalance .path1 {
  &:before {
    content: $pt-mobile-extrabalance-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-mobile-extrabalance .path2 {
  &:before {
    content: $pt-mobile-extrabalance-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-mobile-outline .path1 {
  &:before {
    content: $pt-mobile-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-mobile-outline .path2 {
  &:before {
    content: $pt-mobile-outline-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-mobile-phone {
  &:before {
    content: $pt-mobile-phone;
    color: #00377b;
  }
}
.pt-mobile-promotionalbalance .path1 {
  &:before {
    content: $pt-mobile-promotionalbalance-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-mobile-promotionalbalance .path2 {
  &:before {
    content: $pt-mobile-promotionalbalance-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-mobile-wifi .path1 {
  &:before {
    content: $pt-mobile-wifi-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-mobile-wifi .path2 {
  &:before {
    content: $pt-mobile-wifi-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-mobile .path1 {
  &:before {
    content: $pt-mobile-path1;
    color: rgb(40, 147, 255);
  }
}
.pt-mobile .path2 {
  &:before {
    content: $pt-mobile-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.pt-mobileinternet .path1 {
  &:before {
    content: $pt-mobileinternet-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-mobileinternet .path2 {
  &:before {
    content: $pt-mobileinternet-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-more-calls {
  &:before {
    content: $pt-more-calls;
    color: #00377b;
  }
}
.pt-more {
  &:before {
    content: $pt-more;
    color: #00377d;
  }
}
.pt-multipledevices .path1 {
  &:before {
    content: $pt-multipledevices-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-multipledevices .path2 {
  &:before {
    content: $pt-multipledevices-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-multipledevices .path3 {
  &:before {
    content: $pt-multipledevices-path3;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-natmodem .path1 {
  &:before {
    content: $pt-natmodem-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-natmodem .path2 {
  &:before {
    content: $pt-natmodem-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-networkmonitoring .path1 {
  &:before {
    content: $pt-networkmonitoring-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-networkmonitoring .path2 {
  &:before {
    content: $pt-networkmonitoring-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-notebook-calendar .path1 {
  &:before {
    content: $pt-notebook-calendar-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-notebook-calendar .path2 {
  &:before {
    content: $pt-notebook-calendar-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-notebook-connectivity .path1 {
  &:before {
    content: $pt-notebook-connectivity-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-notebook-connectivity .path2 {
  &:before {
    content: $pt-notebook-connectivity-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-notebook-ip .path1 {
  &:before {
    content: $pt-notebook-ip-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-notebook-ip .path2 {
  &:before {
    content: $pt-notebook-ip-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-notebook-lanconnectivity .path1 {
  &:before {
    content: $pt-notebook-lanconnectivity-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-notebook-lanconnectivity .path2 {
  &:before {
    content: $pt-notebook-lanconnectivity-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-notebook-security .path1 {
  &:before {
    content: $pt-notebook-security-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-notebook-security .path2 {
  &:before {
    content: $pt-notebook-security-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-notebook-share .path1 {
  &:before {
    content: $pt-notebook-share-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-notebook-share .path2 {
  &:before {
    content: $pt-notebook-share-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-notebook .path1 {
  &:before {
    content: $pt-notebook-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-notebook .path2 {
  &:before {
    content: $pt-notebook-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-notification {
  &:before {
    content: $pt-notification;
    color: #00377b;
  }
}
.pt-ok .path1 {
  &:before {
    content: $pt-ok-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-ok .path2 {
  &:before {
    content: $pt-ok-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-online-billing .path1 {
  &:before {
    content: $pt-online-billing-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-online-billing .path2 {
  &:before {
    content: $pt-online-billing-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-open-security .path1 {
  &:before {
    content: $pt-open-security-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-open-security .path2 {
  &:before {
    content: $pt-open-security-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-open-wifi .path1 {
  &:before {
    content: $pt-open-wifi-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-open-wifi .path2 {
  &:before {
    content: $pt-open-wifi-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-order .path1 {
  &:before {
    content: $pt-order-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-order .path2 {
  &:before {
    content: $pt-order-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-package-delivery .path1 {
  &:before {
    content: $pt-package-delivery-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-package-delivery .path2 {
  &:before {
    content: $pt-package-delivery-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-padlock .path1 {
  &:before {
    content: $pt-padlock-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-padlock .path2 {
  &:before {
    content: $pt-padlock-path2;
    margin-left: -1em;
    color: rgb(0, 55, 125);
  }
}
.pt-paquetigos .path1 {
  &:before {
    content: $pt-paquetigos-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-paquetigos .path2 {
  &:before {
    content: $pt-paquetigos-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-parentalcontrol .path1 {
  &:before {
    content: $pt-parentalcontrol-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-parentalcontrol .path2 {
  &:before {
    content: $pt-parentalcontrol-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-password-lock .path1 {
  &:before {
    content: $pt-password-lock-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-password-lock .path2 {
  &:before {
    content: $pt-password-lock-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-passwordok-lockconfirmed .path1 {
  &:before {
    content: $pt-passwordok-lockconfirmed-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-passwordok-lockconfirmed .path2 {
  &:before {
    content: $pt-passwordok-lockconfirmed-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-paused-call .path1 {
  &:before {
    content: $pt-paused-call-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-paused-call .path2 {
  &:before {
    content: $pt-paused-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-pay-merch .path1 {
  &:before {
    content: $pt-pay-merch-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-pay-merch .path2 {
  &:before {
    content: $pt-pay-merch-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-payment .path1 {
  &:before {
    content: $pt-payment-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-payment .path2 {
  &:before {
    content: $pt-payment-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-pdf .path1 {
  &:before {
    content: $pt-pdf-path1;
    color: rgb(0, 55, 125);
  }
}
.pt-pdf .path2 {
  &:before {
    content: $pt-pdf-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-phone-history .path1 {
  &:before {
    content: $pt-phone-history-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-phone-history .path2 {
  &:before {
    content: $pt-phone-history-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-phone-settings .path1 {
  &:before {
    content: $pt-phone-settings-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-phone-settings .path2 {
  &:before {
    content: $pt-phone-settings-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-picture .path1 {
  &:before {
    content: $pt-picture-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-picture .path2 {
  &:before {
    content: $pt-picture-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-pin-code {
  &:before {
    content: $pt-pin-code;
    color: #00377b;
  }
}
.pt-placement .path1 {
  &:before {
    content: $pt-placement-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-placement .path2 {
  &:before {
    content: $pt-placement-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-plan-tvinternet-telephony .path1 {
  &:before {
    content: $pt-plan-tvinternet-telephony-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-plan-tvinternet-telephony .path2 {
  &:before {
    content: $pt-plan-tvinternet-telephony-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-plan-tvinternet .path1 {
  &:before {
    content: $pt-plan-tvinternet-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-plan-tvinternet .path2 {
  &:before {
    content: $pt-plan-tvinternet-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-play-pause .path1 {
  &:before {
    content: $pt-play-pause-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-play-pause .path2 {
  &:before {
    content: $pt-play-pause-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-pluscall .path1 {
  &:before {
    content: $pt-pluscall-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-pluscall .path2 {
  &:before {
    content: $pt-pluscall-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-plusinternet {
  &:before {
    content: $pt-plusinternet;
    color: #00c8ff;
  }
}
.pt-police .path1 {
  &:before {
    content: $pt-police-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-police .path2 {
  &:before {
    content: $pt-police-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-pos-posnet .path1 {
  &:before {
    content: $pt-pos-posnet-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-pos-posnet .path2 {
  &:before {
    content: $pt-pos-posnet-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-premium-plan .path1 {
  &:before {
    content: $pt-premium-plan-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-premium-plan .path2 {
  &:before {
    content: $pt-premium-plan-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-prioritydevice .path1 {
  &:before {
    content: $pt-prioritydevice-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-prioritydevice .path2 {
  &:before {
    content: $pt-prioritydevice-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-privatenetwork .path1 {
  &:before {
    content: $pt-privatenetwork-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-privatenetwork .path2 {
  &:before {
    content: $pt-privatenetwork-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-profilenotebook-user .path1 {
  &:before {
    content: $pt-profilenotebook-user-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-profilenotebook-user .path2 {
  &:before {
    content: $pt-profilenotebook-user-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-ptt-presstospeak .path1 {
  &:before {
    content: $pt-ptt-presstospeak-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-ptt-presstospeak .path2 {
  &:before {
    content: $pt-ptt-presstospeak-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-qosmodem .path1 {
  &:before {
    content: $pt-qosmodem-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-qosmodem .path2 {
  &:before {
    content: $pt-qosmodem-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-rec .path1 {
  &:before {
    content: $pt-rec-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-rec .path2 {
  &:before {
    content: $pt-rec-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-receive-banktranfer .path1 {
  &:before {
    content: $pt-receive-banktranfer-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-receive-banktranfer .path2 {
  &:before {
    content: $pt-receive-banktranfer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-red .path1 {
  &:before {
    content: $pt-red-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-red .path2 {
  &:before {
    content: $pt-red-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-registry .path1 {
  &:before {
    content: $pt-registry-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-registry .path2 {
  &:before {
    content: $pt-registry-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-report .path1 {
  &:before {
    content: $pt-report-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-report .path2 {
  &:before {
    content: $pt-report-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-roaming-large {
  &:before {
    content: $pt-roaming-large;
    color: #00377b;
  }
}
.pt-roaming .path1 {
  &:before {
    content: $pt-roaming-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-roaming .path2 {
  &:before {
    content: $pt-roaming-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-row-bottom {
  &:before {
    content: $pt-row-bottom;
    color: #00377d;
  }
}
.pt-row-left {
  &:before {
    content: $pt-row-left;
    color: #00377d;
  }
}
.pt-row-rigth {
  &:before {
    content: $pt-row-rigth;
    color: #00377d;
  }
}
.pt-row-top {
  &:before {
    content: $pt-row-top;
    color: #00377d;
  }
}
.pt-saas .path1 {
  &:before {
    content: $pt-saas-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-saas .path2 {
  &:before {
    content: $pt-saas-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-safetoschool .path1 {
  &:before {
    content: $pt-safetoschool-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-safetoschool .path2 {
  &:before {
    content: $pt-safetoschool-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-sd .path1 {
  &:before {
    content: $pt-sd-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-sd .path2 {
  &:before {
    content: $pt-sd-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-search-contact .path1 {
  &:before {
    content: $pt-search-contact-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-search-contact .path2 {
  &:before {
    content: $pt-search-contact-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-search {
  &:before {
    content: $pt-search;
    color: #00377b;
  }
}
.pt-secure-pay {
  &:before {
    content: $pt-secure-pay;
    color: #00377b;
  }
}
.pt-security-call .path1 {
  &:before {
    content: $pt-security-call-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-security-call .path2 {
  &:before {
    content: $pt-security-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-security-list .path1 {
  &:before {
    content: $pt-security-list-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-security-list .path2 {
  &:before {
    content: $pt-security-list-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-security-pending .path1 {
  &:before {
    content: $pt-security-pending-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-security-pending .path2 {
  &:before {
    content: $pt-security-pending-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-security-telephony .path1 {
  &:before {
    content: $pt-security-telephony-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-security-telephony .path2 {
  &:before {
    content: $pt-security-telephony-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-security-wifi .path1 {
  &:before {
    content: $pt-security-wifi-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-security-wifi .path2 {
  &:before {
    content: $pt-security-wifi-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-security .path1 {
  &:before {
    content: $pt-security-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-security .path2 {
  &:before {
    content: $pt-security-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-send-banktransfer .path1 {
  &:before {
    content: $pt-send-banktransfer-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-send-banktransfer .path2 {
  &:before {
    content: $pt-send-banktransfer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-send-top-up .path1 {
  &:before {
    content: $pt-send-top-up-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-send-top-up .path2 {
  &:before {
    content: $pt-send-top-up-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-server-checkok .path1 {
  &:before {
    content: $pt-server-checkok-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-server-checkok .path2 {
  &:before {
    content: $pt-server-checkok-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-server-list .path1 {
  &:before {
    content: $pt-server-list-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-server-list .path2 {
  &:before {
    content: $pt-server-list-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-server-preferences .path1 {
  &:before {
    content: $pt-server-preferences-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-server-preferences .path2 {
  &:before {
    content: $pt-server-preferences-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-server-settings .path1 {
  &:before {
    content: $pt-server-settings-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-server-settings .path2 {
  &:before {
    content: $pt-server-settings-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-server-user .path1 {
  &:before {
    content: $pt-server-user-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-server-user .path2 {
  &:before {
    content: $pt-server-user-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-server .path1 {
  &:before {
    content: $pt-server-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-server .path2 {
  &:before {
    content: $pt-server-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-servers .path1 {
  &:before {
    content: $pt-servers-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-servers .path2 {
  &:before {
    content: $pt-servers-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-service-diagnostic .path1 {
  &:before {
    content: $pt-service-diagnostic-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-service-diagnostic .path2 {
  &:before {
    content: $pt-service-diagnostic-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-services .path1 {
  &:before {
    content: $pt-services-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-services .path2 {
  &:before {
    content: $pt-services-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-settings {
  &:before {
    content: $pt-settings;
    color: #00377b;
  }
}
.pt-shaking-hands .path1 {
  &:before {
    content: $pt-shaking-hands-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-shaking-hands .path2 {
  &:before {
    content: $pt-shaking-hands-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-shopping-cart-2 {
  &:before {
    content: $pt-shopping-cart-2;
    color: #00377d;
  }
}
.pt-shopping-cart .path1 {
  &:before {
    content: $pt-shopping-cart-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-shopping-cart .path2 {
  &:before {
    content: $pt-shopping-cart-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-signal-location .path1 {
  &:before {
    content: $pt-signal-location-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-signal-location .path2 {
  &:before {
    content: $pt-signal-location-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-signal .path1 {
  &:before {
    content: $pt-signal-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-signal .path2 {
  &:before {
    content: $pt-signal-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-sms-large {
  &:before {
    content: $pt-sms-large;
    color: #00377b;
  }
}
.pt-sms .path1 {
  &:before {
    content: $pt-sms-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-sms .path2 {
  &:before {
    content: $pt-sms-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-special-services .path1 {
  &:before {
    content: $pt-special-services-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-special-services .path2 {
  &:before {
    content: $pt-special-services-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-specs .path1 {
  &:before {
    content: $pt-specs-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-specs .path2 {
  &:before {
    content: $pt-specs-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-speedcalculator-timer .path1 {
  &:before {
    content: $pt-speedcalculator-timer-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-speedcalculator-timer .path2 {
  &:before {
    content: $pt-speedcalculator-timer-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-star-circle .path1 {
  &:before {
    content: $pt-star-circle-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-star-circle .path2 {
  &:before {
    content: $pt-star-circle-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-star {
  &:before {
    content: $pt-star;
    color: #00377b;
  }
}
.pt-statistics .path1 {
  &:before {
    content: $pt-statistics-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-statistics .path2 {
  &:before {
    content: $pt-statistics-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-store .path1 {
  &:before {
    content: $pt-store-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-store .path2 {
  &:before {
    content: $pt-store-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-subscriptions .path1 {
  &:before {
    content: $pt-subscriptions-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-subscriptions .path2 {
  &:before {
    content: $pt-subscriptions-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-target-server .path1 {
  &:before {
    content: $pt-target-server-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-target-server .path2 {
  &:before {
    content: $pt-target-server-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-television-full .path1 {
  &:before {
    content: $pt-television-full-path1;
    color: rgb(55, 200, 70);
  }
}
.pt-television-full .path2 {
  &:before {
    content: $pt-television-full-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.pt-television-outline .path1 {
  &:before {
    content: $pt-television-outline-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-television-outline .path2 {
  &:before {
    content: $pt-television-outline-path2;
    margin-left: -1em;
    color: rgb(1, 55, 123);
  }
}
.pt-television {
  &:before {
    content: $pt-television;
    color: #00377b;
  }
}
.pt-ticket-GO .path1 {
  &:before {
    content: $pt-ticket-GO-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-ticket-GO .path2 {
  &:before {
    content: $pt-ticket-GO-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-ticket-money .path1 {
  &:before {
    content: $pt-ticket-money-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-ticket-money .path2 {
  &:before {
    content: $pt-ticket-money-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-ticketinformation .path1 {
  &:before {
    content: $pt-ticketinformation-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-ticketinformation .path2 {
  &:before {
    content: $pt-ticketinformation-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tigo-backup .path1 {
  &:before {
    content: $pt-tigo-backup-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-tigo-backup .path2 {
  &:before {
    content: $pt-tigo-backup-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-tigo-online .path1 {
  &:before {
    content: $pt-tigo-online-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-tigo-online .path2 {
  &:before {
    content: $pt-tigo-online-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-timer .path1 {
  &:before {
    content: $pt-timer-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-timer .path2 {
  &:before {
    content: $pt-timer-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-top-up .path1 {
  &:before {
    content: $pt-top-up-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-top-up .path2 {
  &:before {
    content: $pt-top-up-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-triple-play .path1 {
  &:before {
    content: $pt-triple-play-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-triple-play .path2 {
  &:before {
    content: $pt-triple-play-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-3d .path1 {
  &:before {
    content: $pt-tv-3d-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-3d .path2 {
  &:before {
    content: $pt-tv-3d-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-cable {
  &:before {
    content: $pt-tv-cable;
    color: #00377b;
  }
}
.pt-tv-decoder .path1 {
  &:before {
    content: $pt-tv-decoder-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-decoder .path2 {
  &:before {
    content: $pt-tv-decoder-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-digital .path1 {
  &:before {
    content: $pt-tv-digital-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-digital .path2 {
  &:before {
    content: $pt-tv-digital-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-favoriteticket .path1 {
  &:before {
    content: $pt-tv-favoriteticket-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-favoriteticket .path2 {
  &:before {
    content: $pt-tv-favoriteticket-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-guide .path1 {
  &:before {
    content: $pt-tv-guide-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-guide .path2 {
  &:before {
    content: $pt-tv-guide-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-hd .path1 {
  &:before {
    content: $pt-tv-hd-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-hd .path2 {
  &:before {
    content: $pt-tv-hd-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-list .path1 {
  &:before {
    content: $pt-tv-list-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-list .path2 {
  &:before {
    content: $pt-tv-list-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-live .path1 {
  &:before {
    content: $pt-tv-live-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-live .path2 {
  &:before {
    content: $pt-tv-live-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-movies {
  &:before {
    content: $pt-tv-movies;
    color: #00377b;
  }
}
.pt-tv-multiroom .path1 {
  &:before {
    content: $pt-tv-multiroom-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-multiroom .path2 {
  &:before {
    content: $pt-tv-multiroom-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-recommender .path1 {
  &:before {
    content: $pt-tv-recommender-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-recommender .path2 {
  &:before {
    content: $pt-tv-recommender-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-remotecontrol .path1 {
  &:before {
    content: $pt-tv-remotecontrol-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-remotecontrol .path2 {
  &:before {
    content: $pt-tv-remotecontrol-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-satellite .path1 {
  &:before {
    content: $pt-tv-satellite-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-satellite .path2 {
  &:before {
    content: $pt-tv-satellite-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-sofa .path1 {
  &:before {
    content: $pt-tv-sofa-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-sofa .path2 {
  &:before {
    content: $pt-tv-sofa-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-ticketvideo .path1 {
  &:before {
    content: $pt-tv-ticketvideo-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-ticketvideo .path2 {
  &:before {
    content: $pt-tv-ticketvideo-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-tigoplay .path1 {
  &:before {
    content: $pt-tv-tigoplay-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-tigoplay .path2 {
  &:before {
    content: $pt-tv-tigoplay-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-videochannels .path1 {
  &:before {
    content: $pt-tv-videochannels-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-videochannels .path2 {
  &:before {
    content: $pt-tv-videochannels-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-tv-vod .path1 {
  &:before {
    content: $pt-tv-vod-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-tv-vod .path2 {
  &:before {
    content: $pt-tv-vod-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-unlimited-calls .path1 {
  &:before {
    content: $pt-unlimited-calls-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-unlimited-calls .path2 {
  &:before {
    content: $pt-unlimited-calls-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-unlimitedshippingpackages .path1 {
  &:before {
    content: $pt-unlimitedshippingpackages-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-unlimitedshippingpackages .path2 {
  &:before {
    content: $pt-unlimitedshippingpackages-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-user-chat .path1 {
  &:before {
    content: $pt-user-chat-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-user-chat .path2 {
  &:before {
    content: $pt-user-chat-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-user-exit .path1 {
  &:before {
    content: $pt-user-exit-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-user-exit .path2 {
  &:before {
    content: $pt-user-exit-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-user-invalid .path1 {
  &:before {
    content: $pt-user-invalid-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-user-invalid .path2 {
  &:before {
    content: $pt-user-invalid-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-user-logout .path1 {
  &:before {
    content: $pt-user-logout-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-user-logout .path2 {
  &:before {
    content: $pt-user-logout-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-user-profile {
  &:before {
    content: $pt-user-profile;
    color: #01377b;
  }
}
.pt-user-protection .path1 {
  &:before {
    content: $pt-user-protection-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-user-protection .path2 {
  &:before {
    content: $pt-user-protection-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-user-question .path1 {
  &:before {
    content: $pt-user-question-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-user-question .path2 {
  &:before {
    content: $pt-user-question-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-user-valid .path1 {
  &:before {
    content: $pt-user-valid-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-user-valid .path2 {
  &:before {
    content: $pt-user-valid-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-user {
  &:before {
    content: $pt-user;
    color: #01377b;
  }
}
.pt-users .path1 {
  &:before {
    content: $pt-users-path1;
    color: rgb(1, 55, 123);
  }
}
.pt-users .path2 {
  &:before {
    content: $pt-users-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-velocity-speed .path1 {
  &:before {
    content: $pt-velocity-speed-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-velocity-speed .path2 {
  &:before {
    content: $pt-velocity-speed-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-video-surveillance .path1 {
  &:before {
    content: $pt-video-surveillance-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-video-surveillance .path2 {
  &:before {
    content: $pt-video-surveillance-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-virtual-servers .path1 {
  &:before {
    content: $pt-virtual-servers-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-virtual-servers .path2 {
  &:before {
    content: $pt-virtual-servers-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-voicemail .path1 {
  &:before {
    content: $pt-voicemail-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-voicemail .path2 {
  &:before {
    content: $pt-voicemail-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-volume .path1 {
  &:before {
    content: $pt-volume-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-volume .path2 {
  &:before {
    content: $pt-volume-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-waiting-call .path1 {
  &:before {
    content: $pt-waiting-call-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-waiting-call .path2 {
  &:before {
    content: $pt-waiting-call-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-web-asset .path1 {
  &:before {
    content: $pt-web-asset-path1;
    color: rgb(0, 55, 125);
  }
}
.pt-web-asset .path2 {
  &:before {
    content: $pt-web-asset-path2;
    margin-left: -1em;
    color: rgb(0, 199, 255);
  }
}
.pt-web-hosting .path1 {
  &:before {
    content: $pt-web-hosting-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-web-hosting .path2 {
  &:before {
    content: $pt-web-hosting-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-widgets .path1 {
  &:before {
    content: $pt-widgets-path1;
    color: rgb(0, 55, 125);
  }
}
.pt-widgets .path2 {
  &:before {
    content: $pt-widgets-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-wifi-highsignal {
  &:before {
    content: $pt-wifi-highsignal;
    color: #00377b;
  }
}
.pt-wifi-lowsignal .path1 {
  &:before {
    content: $pt-wifi-lowsignal-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-wifi-lowsignal .path2 {
  &:before {
    content: $pt-wifi-lowsignal-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-wifi-mediumsignal .path1 {
  &:before {
    content: $pt-wifi-mediumsignal-path1;
    color: rgb(0, 200, 255);
  }
}
.pt-wifi-mediumsignal .path2 {
  &:before {
    content: $pt-wifi-mediumsignal-path2;
    margin-left: -1em;
    color: rgb(0, 55, 123);
  }
}
.pt-world-server .path1 {
  &:before {
    content: $pt-world-server-path1;
    color: rgb(0, 55, 123);
  }
}
.pt-world-server .path2 {
  &:before {
    content: $pt-world-server-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
.pt-xls .path1 {
  &:before {
    content: $pt-xls-path1;
    color: rgb(0, 55, 125);
  }
}
.pt-xls .path2 {
  &:before {
    content: $pt-xls-path2;
    margin-left: -1em;
    color: rgb(0, 200, 255);
  }
}
