/*
COLORS
*/
$neutral_1: #ffffff; //Blanco
//$neutral_2: #787878; // Text
$neutral_2: #565656; // Text
$neutral_4: #fafafa; //Main BG
$neutral_5: #cdcdcd; //

$primary_1: #00377d; //Azul TIGO
$primary_3: #001950; //NEW Top header or Menú selector

$secundary_1: #00c8ff; //Cyan
$secundary_2: #3264dc; //Press or Highlight
$secundary_3: #198afd; //Hover
$secundary_5: #ffbe00; //New Yellow
$secundary_6: #00000a; //New black or blue dark

$utility_1: #37c846; //Product Families television
$utility_2: #f01e46; //Error
$utility_3: #ffaa32; //warning

$product_1: #f0781e; //Product Families Internet
$product_2: #2896ff; //Products mobile

$grey_1: #f8f9fa; //Gris 1
$grey_2: #f1f2f3; //Gris 2
$grey_25: #e9ebed; //Gris 25
$grey_3: #dddfe1; //Gris 3
$grey_4: #ced0d2; //Gris 4
$grey_5: #c4c6c8; //Gris 5
$grey_6: #9d9fa1; //Gris 6
$grey_7: #5c5e60; //Gris 7
$grey_8: #393a3b; //Gris 8
$grey_85: #303132; //Gris 85
$grey_9: #1E1F20; //Gris 9
$black: #00000A; //Negro base
/*
FONTS
*/
//Family
$r-thin: 'robotothin'; /* 200 */
$r-light: 'robotolight'; /* 300 */
$r-regular: 'robotoregular', sans-serif; /* 500 */
$r-medium: 'robotomedium'; /* 700 */
$r-semibold: 'robotobold'; /* 800 */
$r-bold: 'robotoblack'; /* 900 */
$icons-tigoune: 'icoTigoUne';

//font-sizes
$font_1x: 0.625rem;
$font_2x: 0.75rem;
$font_3x: 0.875rem;
$font_4x: 1.25rem; //20px
$font_5x: 1.625rem;
$font_6x: 2.25rem;
$font_7x: 2.375rem; //38px
$font_8x: 1.75rem; //28px
$font_9x: 1.62rem;
$font_10x: 1.375rem;
$font_11x: 2rem;
$font_12x: 1.125rem; //18px
$font_13x: 1.85rem;
$font_14x: 1.5rem; //24px
$font_15x: 2.5rem;
$font_16x: 0.5rem;
$font_17x: 3rem; //48px
$font_18x: 1rem;
$font_19x: 1.16rem;
$font_20x: 4.5rem;
$font_21x: 5rem;
$font_22x: 3.5rem;
$font_23x: 6rem; //96px
$font_24x: 3.75rem; //60px
$font_25x: 2.125rem; //34px
$font_26x: 1.875rem; //30px

//Size UX
$size_1x: 12px;
/*
GRID
*/
$grid: 1170px;
$grid-gap: 10px;

/*
BORDERS AND SHADOWS
*/
//Box shadow
$shadow_1: 0px 10px 20px 0 rgba(0, 0, 10, 0.25); //hight shadow
$shadow_2: 0px 2px 10px 0 rgba(0, 0, 10, 0.25); //medium shadow
$shadow_3: 0px 2px 2px 0 rgba(0, 0, 10, 0.25); //low shadow
$shadow_4: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24); //tiny shadow
$shadow_5: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3); //Dialog shadow
$shadow_6: 0 0 16px 0 rgba(0, 0, 0, 0.18), 0 16px 16px 0 rgba(0, 0, 0, 0.24); //Drawer shadow

//Border
$border_1: 1px solid;
$border_2: 2px solid;
$border_3: 3px solid;
/*
TRANSITIONS
*/
$transition_1: all 0.3s;
$transition_2: ease 0.2s;
$transition_3: 0.5s all;
/*
TRANSFORM
*/
$transform_1: translate(-50%, -50%);
$transform_2: translate(12px, 15px) scale(1);
$transform_3: translate(12px, -10px) scale(1);
/*
MEDIA QUERIES
*/
$mobile_L: 480px;
$mobile: 768px;
$tablet: 1024px;
$desktop: 1280px;

/*
ASSETS PATH
*/

//Images
//ruta para brand tigo
//$imagesPath: "./assets/images/";
//ruta para mi cuenta en modules
//$imagesPath: "../images/";
//ruta para mi cuenta Theme
//$imagesPath: "../../images/";
//ruta para Discovery theme
//$imagesPath: "../../images/";
//Ruta para tutorial
//$imagesPath: "../assets/images/";
//ruta para testing local y 206
//$imagesPath: "../../../../../assets/images/";
$imagesPath: 'https://atomic.tigocloud.net/source/assets/images/';
//$imagesPath: "../../assets/images/";
//Fonts
//ruta para brand tigo
//$fontsPath: "./assets/fonts/";
//ruta para mi cuenta en modules
//$fontsPath: "../fonts/";
//ruta para mi cuenta en theme
//$fontsPath: "../../vendors/fonts/";
//ruta para Discovery theme
//$fontsPath: "../../vendors/fonts/";
//Ruta para tutorial
//$fontsPath: "../assets/fonts/";
//ruta para testing local y 206
//$fontsPath: "../../../../../assets/fonts/";
$fontsPath: 'https://atomic.tigocloud.net/source/assets/fonts/';
//$fontsPath: "../../assets/fonts/";
