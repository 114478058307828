@import "../../source/base/base.scss";
body {
  font-family: $r-regular;
}
.example-code{
  //background: #eeeeee;
  display: inline-block;
  margin: 60px auto;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  .description {
    float: left;
    width: 40%;
    @include for-mobile{
      width: 100%;
      float: initial;
      margin-bottom: 2rem;
    }
  }
  .snippet{
    float: right;
    width: 60%;
    @include for-mobile{
      width: 100%;
      float: initial;
    }
  }
  pre{
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f5f2f0;
    padding: 0 10px;
    overflow: scroll;
    margin: 0;
    max-height: 120px;
    &::-webkit-scrollbar{
      background: transparent;
      width: 4px;
    }
    &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-corner{
      background: #ccc;
      width: 4px;
    }
  }
  code{
    white-space: pre-wrap;
  }
}
.color{
  display: inline-block;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,.06);
  box-sizing: border-box;
  margin: 12px;
  min-width: 250px;
  > div{
    float: left;
    width: 100%;
    height: 100px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .color-description{
    //border-top: 1px solid transparentize($neutral_2, .5);;
    padding: 10px;
    width: calc(100% - 20px);
    height: auto;
    border-radius: 0;
    h5{
      color: $neutral_2;
      font-weight: bold;
    }
  }
  .neutral_1{
    background: $neutral_1;
  }
  .neutral_2 {
    background: $neutral_2;
  }
  .neutral_4 {
    background: $neutral_4;
  }
  .primary_1 {
    background: $primary_1;
  }
  .primary_3{
    background: $primary_3;
  }
  .secundary_1 {
    background: $secundary_1;
  }
  .secundary_2 {
    background: $secundary_2;
  }
  .secundary_3 {
    background: $secundary_3;
  }
  .secundary_5 {
    background: $secundary_5;
  }
  .secundary_6 {
    background: $secundary_6;
  }
  .utility_1 {
    background: $utility_1;
  }
  .utility_2 {
    background: $utility_2;
  }
  .utility_3 {
    background: $utility_3;
  }
  .product_1 {
    background: $product_1;
  }
  .product_2 {
    background: $product_2;
  }
  .gradient_miscellanea{
    background: transparentize($neutral_2, .5)
  }
  .gradient_menuselector{
    background: transparentize($primary_3, .6)
  }
  @include for-mobile {
    float: left;
    width: 46%;
    margin: 12px 2%;
    min-width: auto;
  }
}
.gradient {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  color: #ffffff;
  h4 {
    background: #00377b;
    color: #ffffff;
    padding: 24px;
  }
  .at-element-list{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    li{
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
      text-align: center;
      color: #ffffff;
      padding: 48px;
      font-size: 28px;
      &.color-90{
        background: transparentize(#00377b, .1)
      }
      &.color-80{
        background: transparentize(#00377b, .2)
      }
      &.color-70{
        background: transparentize(#00377b, .3)
      }
      &.color-60{
        background: transparentize(#00377b, .4)
      }
      &.color-50{
        background: transparentize(#00377b, .5)
      }
      &.color-40{
        background: transparentize(#00377b, .6)
      }
    }
    @include for-mobile {
      display: block;
      li{
        padding: 12px;
      }
    }
  }
}
/*
Slider owl carousel
*/
.owl-carousel{
  .owl-nav {
    width: 100%;
    position: absolute;
    top: 48%;
    left: 0;
    opacity: 0;
    button{
      background: transparentize($primary_1, .6);
      border-radius: 100%;
      height: 36px;
      width: 36px;
      margin: 0 24px;
      outline: none;
      &:before{
        font-family: $icons-tigoune;
        font-size: 36px;
        font-weight: bold;
        color: $neutral_1;
      }
      span{
        display: none;
      }
      &.owl-prev{
        float: left;
        background: transparentize($primary_1, .6);
        &:before{
          content: "\e95e";
        }
        &:hover{
          background: transparentize($neutral_2, .5);;
        }
      }
      &.owl-next{
        float: right;
        background: transparentize($primary_1, .6);
        &:before{
          content: "\e92d";
        }
        &:hover{
          background: transparentize($neutral_2, .5);;
        }
      }
    }
    @include for-mobile {
      display: none;
    }
  }
  .owl-dots{
    position: absolute;
    bottom: 1%;
    left: 0;
    width: 100%;
    text-align: center;
    button{
      &.owl-dot{
        padding: 8px;
        margin: 0 2px;
        outline: none;
        span{
          background: transparentize($primary_1, .6);
          width: 6px;
          height: 6px;
          float: left;
          border-radius: 100%;
        }
        &.active{
          span{
            background: $neutral_4;
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
  &:hover{
    .owl-nav {
      opacity: 1;
    }
  }
}
#control-switch{
  .at-switch {
    & input {
      &:checked + .at-loader-switch:before {
        background-image: url("../../source/assets/images/icons/loader.svg");
      }
    }
  }
}
#hight-slider{
  .or-hight-slider{
    .controls{
      .play{
        background-image: url('../../source/assets/images/icons/ic-play.svg');
      }
      .stop{
        background-image: url('../../source/assets/images/icons/ic-pause.svg');
      }
    }
  }
}
#card-paquetes{
  .ml-card-paquetes .content-card .information {
    .icon{
      &.whatsapp{
        background-image: url("../../source/assets/images/icons/whatsapp.svg");
      }
      &.facebook{
        background-image: url("../../source/assets/images/icons/facebook.svg");
      }
    }
  }
}

/* estilos basicos */
#grid-same-width, #grid-12-v2, #grid-9-v2, #grid-3-v2, #grid-padding-v2, #grid-start-v2, #grid-end-v2, #grid-center-v2, #grid-between-v2, #grid-arround-v2{
  .grid-style {
    margin-bottom: 1em;
    .box {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      padding: 20px 10px;
      color: #787878;
      font-size: 0.88rem;
    }
  }
  .grid-style {
    margin-bottom: 1em;
    .box {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      padding: 20px 10px;
      color: #787878;
      font-size: 0.88rem;
    }
  }
  .at-logo-image {
    background-color: $neutral_2;
  }
  .at-font-p.p-top-on-desktop-85 {
    @include for-tablet {
      padding-top: 85px;
      display: block;
    }
  }
  .at-cl {
    background: #E7E7E7;
    padding: 10px;
    font-size: 12px;
    font-family: Arial;
    border: 1px solid #F0F0F0;
    text-align: center;
  }
  .at-table-description {
    font-family: Arial;
    font-size: 12px;
    border-collapse: collapse;
    text-align: center;
    thead tr {
      background-color: #333;
      color: #fff;
    }
    tbody {
      tr {
        background: #DEDEDE;
        &:nth-child(odd) {
          background-color: #E0E0E0;
        }
      }
    }
    th, td {
      padding: 10px;
    }
  }
}

/* estilos notifications solo para testing */
#alert-notification .ml-notification-alert, #attention-notification .ml-notification-attention, #success-notification .ml-notification-success, #information-notification .ml-notification-information{
  display: flex;
  opacity: 1;
}
#demo-notification{
  margin: 20px 0;
}
// #header-account, #header-discovery{
//   .dropdown-menu-button {
//     .ic-burguer {
//       &:before{
//         content: url("../../source/assets/images/icons/burger.svg");
//       }
//     }
//   }
// }

/* cards */
#card-paquetes{
  .ml-card-paquetes{
    img{
      @include for-mobile{
        height: auto;
      }
    }
  }
}

/* logo link */
#logo-link, #tigo-logo-link{
  .ml-logo-link{
    img{
      background: $primary_1;
      width: 200px;
    }
  }
}

/* footer */
#footer-firm, #footer-organism{
  .ic-facebook-footer{
    &:before{
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      color: #7f9bbd;
      content: url(../../source/assets/images/icons/ic-facebook-footer.svg);
    }
  }
  .ic-twitter-footer{
    &:before{
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      color: #7f9bbd;
      content: url(../../source/assets/images/icons/ic-twitter-footer.svg);
    }
  }
  .ic-youtube-footer{
    &:before{
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      color: #7f9bbd;
      content: url(../../source/assets/images/icons/ic-youtube-footer.svg);
    }
  }
}
#footer-navigation, #footer-organism{
  .ic-phone-footer{
    &:before{
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      color: #7f9bbd;
      content: url(../../source/assets/images/icons/ic-phone.svg);
    }
  }
}
#footer-services, #footer-organism{
  .ic-user-protection{
    &:before{
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      color: #7f9bbd;
      content: url(../../source/assets/images/icons/ic-user-protection.svg);
      margin-top: -15px;
    }
  }
  .ic-pqr{
    &:before{
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      color: #7f9bbd;
      content: url(../../source/assets/images/icons/ic-pqr.svg);
      margin-left: 6px;
      margin-top: -13px;
    }
  }
}
//dividers
#dividers-with-background{
  background: #00377B;
}

//accordion
#accordion{
  .ml-accordion{
    .at-card-low-emphasis{
      .accordion-item:nth-child(3){
        .item-body{
          .at-font-p{
            display: inline-block;
            vertical-align: top;
            width: 95%;
            @include for-mobile-s{
              width: 100%;
            }
          }
        }
      }
      .accordion-item:nth-child(5){
        .item-body{
          display: flex;
          align-items: center;
          .at-radio-button{
            margin-bottom: 0;
            margin-right: .5rem;
          }
          > label{
            margin-right: 3rem;
          }
        }
      }
    }
  }
}
/* estilos de atomo textarea */
#textarea{
  .at-input-label{
    @include for-mobile{
      width: 100%;
    }
    .at-textarea{
      width: 683px;
      @include for-mobile{
        width: 90%;
      }
    }
  }
}
/*oculta temporalmente los fragmentos que no sean atomos*/
// #card-paquetes, #card-servicios, #card-big, #card-medium, #card-small, #card-informative, #ml-card-informative, #card-block, #block-hero, #block-revendedor, #block-media, #basic-search, #primary_navigation, #banner-slider, #banner-center, #backdrop, #popup, #popup-form, #popup-advertence, #popup-list, #footer-firm, #footer-navigation, #footer-services, #accordion, #general-icon-circular, #alert-notification, #attention-notification, #success-notification, #information-notification, #general-interest, #process-overlay, #success-overlay, #hight-slider, #general-icon-circular_slider-mobile, #logo-link, #general-interest-organism, #popup-notification, #login, #signin, #form-we-call, #footer-organism, #header-discovery, #header-account, #general-tabs, #specific-tabs, #pagination, #breadcrumb, #code-email, #tigo-logo-link, #linecolor-notification{
//   display: none;
// }
#paragraphs {
  em,p,small{
    display: block;
  }
}

/*estilos de estado de los fragmentos*/
.status-fragmento{
  font-family: "robotoregular",sans-serif,sans-serif;
  color: $primary_1;
  &.beta{
    strong{
      font-size: $font_11x;
      color: $utility_2;
    }
  }
  &.estable{
    strong{
      font-size: $font_11x;
      color: $utility_1;
    }
  }
}
#linecolor-notification .btn-demo {
  display: table;
  margin: 10px 0;
}
#dropdown-up{
  .ml-dropdown-up .action-dropdown{
    margin: 0;
  }
  .ml-dropdown-up .action-dropdown .ic-flag-colombia, .ml-dropdown-up .popup-select .ic-flag-colombia{
    content: url(../../source/assets/images/muestras/bandera-colombia.png);
  }
}
#footer-organism{
  .ml-dropdown-up .action-dropdown .ic-flag-colombia, .ml-dropdown-up .popup-select .ic-flag-colombia{
    content: url(../../source/assets/images/muestras/bandera-colombia.png);
  }
}
#basic-search{
  .container-testing{
    background: $primary_1;
    //padding: 0 0 5rem 0;
    text-align: right;
  }
}



//AJUSTES 2020
body {
  font-size: 16px;
  .iframe-responsive{
    //height: 100vh;
    border: none;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    // @media(max-width: 1900px){
    //   height: 768px;
    // }
    // @media(max-width: 1600px){
    //   height: 600px;
    // }
    // @media(max-width: 1300px){
    //   height: 565px;
    // }
  }
  .iframe-responsive.ng, .iframe-responsive.gr{
    min-height: 6470px;
  }
  .iframe-responsive.log{
    min-height: 1670px;
  }
  .iframe-responsive.an{
    min-height: 600px;
  }
  .mob{
    width: 375px;
    border: 12px solid #000;
    border-radius: 35px;
    height: 667px;
    //border-bottom: 40px solid #000;
  }
}
.int{
  background: #fff;
  //padding-bottom: 200px;
  min-height: 550px;
  .pad{
    padding: 10px;
  }
  .titu{
    font-family:"robotoregular";
    color: $neutral_2;
  }
}
#wrapper #sidebar-nav, #wrapper .main{
  padding-top: 100px;
}
.clear{
  clear: both;
}
header {
  .menu{
    top: 0;
  }
}
.navbar-default .brand{
  padding:0 15px;
  display: block!important;
  img{
    width: 65px;
  }
  h1{
    color: #d2d2d2;
    font-size: 30px;
    display: flex;
    align-items: center;
  }
}
.container-content, .main-content{
  padding:0;
  margin:0;
  .tab-pane{
    //padding-top: 15px;
  }
  .seccion {
    font-family: $r-medium;
    font-size:$font_7x;
    margin-bottom: -25px;
    padding-left: 15px;
    color: $primary_1;
  }
  .devices{
    margin: 10px 10px 0 0;
    padding: 0;
    float: right;
    li{
      list-style: none;
      display: inline-block;
      a {
        font-size: 16px;
        background: #ccc;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 5px 10px;
        i{
          margin-right: 3px;
        }
      }
      .desktop{
        margin-right: 8px;
        background: $secundary_1;
      }
      .act{
        background: $secundary_1;
      }
      .no{
        background: #ccc;
      }
    }
  }
    .description{
      padding: 15px;
      margin-bottom: 20px;
      .estable, .beta, .add, .lst{
        background: #37C846;
        color: #fff;
        padding: 5px 8px;
        display: inline-block;
        font-size: 13px;
        position: relative;
        top: -6px;
        margin-right: 5px;
      }
      .beta{
        background:#F01E46;
      }
      .add{
        background: #00C8FF;
      }
      .date{
        font-size: 16px;
        color: #565656;
        font-family: "robotoregular";
        margin-left: 5px;
      }
      .lst{
        background: #252c35;
        margin-left: 10px;
      }
      p{
        font-size: 16px;
      }
      img{
        width: 100%;
      }
    }
    .cdn{
      position: relative;
      label{
        margin-bottom: 0;
        top:-3px!important;
        margin-right: 8px!important;
      }
      h3{
        margin:0;
        font-family: "robotobold";
        font-size: $font_6x;
      }
      .js-copy{
        position: absolute;
        top: 10px;
        right: 10px;
        background:$secundary_1;
        font-size: 20px;
        border: none;
        border-radius: 16px;
        padding: 3px 10px;
        cursor: pointer;
        i{
          font-size: 16px;
          color: #fff;
        }
      }
      pre{
        margin: 0;
        padding: 0;
      }
      .prettyprint{
        width: 100%;
        //margin: 10px 0;
        //padding: 0 20px;
        background: #fff;
      }
      code .tag, pre .tag{
        color: navy;
      }
      code .atn, pre .atn, code .kwd, pre .kwd{
        color: teal;
      }
      code .atv, pre .atv, code .str, pre .str{
        color: #d14;
      }
      .code{
        font-size: 16px;
      }
      .changes{
        label{
          font-weight: 300;
          margin: 0 0 6px 0;
          strong{
            font-family: "robotobold";
          }
        }
        a{
          display: block;
          color: $secundary_1;
          line-height: 25px;
        }
      }
    }
    .pad{
      padding: 0 15px;
    }
    .actions{
      position: relative;
      button{
        background:$secundary_1;
        color: #fff;
        border: none;
        border-radius: 16px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 20px;
      }
    }
    .example-code{
      //background: #fff;
      margin:0;
      padding:0;
      .snippet {
        width: 100%;
        float: none;
        //overflow: hidden;
        //height: 100vh;
        //padding-bottom: 30px;
        .top{
          background: #fff;
          padding: 6px;
          border-radius: 8px 8px 0 0;
        }
        h4{
          float: left;
          color: #666;
        }
        .prettyprint{
          margin: 10px 0 0 0;
          max-height: none;
          height: 100%;
          border: none;
          overflow: hidden;
          border-radius: 8px;
          padding: 2px 10px;
          background: #151a1f;
          width: 100%;
          li {
            background: #151a1f;
          }
        }
        code .tag, pre .tag{
          color: #f3737e;
        }
        code .atn, pre .atn, code .kwd, pre .kwd{
          color: #fbad63;
        }
        code .atv, pre .atv, code .str, pre .str{
          color: #aae87c;
        }
        @media (max-width: 1100px){
          width: 100%;
        }
      }
      .mini{
        width: 30%;
        height: 112px;
        @media (max-width: 1100px){
          width: 100%;
        }
      }
      .medium{
        width: 40%;
        height: 150px;
        @media (max-width: 1100px){
          width: 100%;
        }
      }
    }
    .divid{
      background:$primary_1;
      padding: 12px 0;
    }
  #favicon{
    .example{
      img{
        width: 3%;
      }
    }
  }
  .res{
    position: absolute;
    bottom: 0;
    right: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #ababab;
  }
}
.icon-paq{
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 35px 0 0 0;
  //background: #f5f4f4;
  a{
    text-align: center;
    width: 16%;
    margin: 10px 15px;
    border-radius: 10px;
    text-decoration: none;
    color: #565656;
    padding: 10px 5px;
    position: relative;
    cursor: pointer;
        // transition: all 0.2s ease-in-out;
    &:hover {
      .mls{
        color: $secundary_1;
      }
    }
    .js-copy{
      position: absolute;
      right: 10px;
      display: none;
      background:$secundary_1;
      font-size: 20px;
      border: none;
      border-radius: 16px;
      padding: 3px 8px;
      top: 10px;
      cursor: pointer;
      i{
        font-size: 16px;
        color: #fff;
      }
    }
  }
  i{
    font-size: 48px;
  }
  .mls{
    display: block;
    font-family: "robotoregular";
    font-size: 12px;
  }
  .prettyprint{
    display: none;
  }
  .ic.is-active{
    width: 35%;
    -moz-box-shadow: 1px 0px 9px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 1px 0px 9px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 0px 9px rgba(0, 0, 0, 0.3);
    padding: 10px;
    //background: #fff;
    .prettyprint{
      display: block;
      border-radius: 6px;
      padding: 6px;
      background: #151a1f;
      li {
        background: #151a1f;
      }
    }
    code .tag, pre .tag{
      color: #f3737e;
    }
    code .atn, pre .atn, code .kwd, pre .kwd{
      color: #fbad63;
    }
    code .atv, pre .atv, code .str, pre .str{
      color: #aae87c;
    }
    .code{
      white-space: pre-line;
    }
    .js-copy{
      display: block;
    }
  }
}
.icon-paq.anim{
  padding-top:0;
  a{
    width: 30%;
    img{
      width: 100%;
    }
  }
}
.dark{
  background: #00377D;
  .ic.is-active{
    background:#151a1f;
  }
  .mls{
    color: #fff;
  }
}
.state, .stateA, .stateN{
  font-size: 10px;
  background: $utility_3;
  color: #fff;
  padding: 1px 4px;
  border-radius: 2px;
  margin: 0;
  position: relative;
  top: -3px;
}
.stateA{
  background: $secundary_1;
}
.stateN{
  background: $utility_1;
}
#sidebar-nav{
  ul{
    li{
      .active{
        color: $secundary_1;
      }
    }
  }
  .sub{
    ul{
      li{
        background: #111;
      }
    }
  }
}
.fix{
  overflow: hidden;
}
.hover{
  overflow: hidden;
}
.inicio{
  padding: 15px;
  h2{
    //font-size: 25px;
    margin-bottom: 10px;
  }
  // a{
  //   color: $secundary_1;
  // }
  .at-button-primary {
    font-size: 14px;
  }
  .enlace{
    color: #00C8FF;
  }
  h3{
    margin-bottom: 10px;
  }
  .img{
    max-width: 800px;
    margin: 0 auto;
    img{
      width: 100%;
      height: auto;

  }
  }
}
.navbar-default{
  display: flex;
    align-items: center;
}
.navbar-btn{
  margin-left: 15px;
}
.cont-b{
  padding: 0 18px;
  position: fixed;
  z-index: 10;
  background: #2B333E;
}
.buscador{
  padding: 0 18px;
  margin: 10px 0 30px 0;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  i{
    font-size: 20px;
  }
}
#inputBusqueda{
  //width: 100%;
    height: 50px;
    //margin: 0 25px;
    padding-left: 10px;
    font-size: 18px;
    background:transparent;
    border: none;
    color: #ccc;
    &:focus{
      outline: none;
    }
    &::placeholder{
      color: #ccc;
      font-size: 15px;
    }
}
.search-sand{
	width: 30%;
	background: transparentize(#252c35, .1);
	padding:20px;
	max-height: 480px;
	overflow-x: hidden;
	display: none;
  position: absolute;
  top: 73px;
  left: 305px;
  font-size: 18px;
  border-radius: 6px;
    line-height: 37px;
  -webkit-box-shadow: 1px 2px 17px rgba(2, 2, 2, 0.2);
    box-shadow: 1px 2px 17px rgba(2, 2, 2, 0.2);
    a{
      color: #fff;
    }
}

.search-table{
	width: 100%;
}
.slimScrollBar{
  background: transparent!important;
}
.dataTables_length, #searchTable_filter, .dataTables_info, .dataTables_paginate{
  display: none;
}
.none{
  display: none;
}
#negative-tone{
  background: #00377D;
}
.sidebar .nav > li > a[data-toggle="collapse"] .icon-submenu{
  -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.filter{
  #icon-filter{
    width: 91%;
    height: 35px;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    position: fixed;
    z-index: 10;
    -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
        margin: 0 30px;
  }
}
.accessible-site{
  display: inline-block;
  vertical-align: middle;
  //margin: 0 50px 0 0;
  position: absolute;
  right: 20px;
  .icon-accessible{
    display: flex;
    align-items: center;
    span{
      margin-right: 10px;
    }
    @include for-tablet{
      margin-left: 14px;
    }
    i{
      color: #00C8FF;
      cursor: pointer;
      font-size: 32px;
    }
  }
  .content-accessible{
    display: none;
    position: absolute;
    top: 35px;
    right: 0;
    background: #fff;
    width: 420px;
    padding: 10px 20px;
    box-sizing: border-box;
    -webkit-box-shadow: 1px 2px 17px rgba(2, 2, 2, 0.2);
      box-shadow: 1px 2px 17px rgba(2, 2, 2, 0.2);
    .content-btn-size-accessible{
      width: 55%;
      display: inline-block;
      text-align: right;
      @include for-tablet{
        width: 205px;
      }
    }
    .title-content-accessible{
      font-size: 1.25rem;
    }
    .accessible-item{
      p{
        display: inline-block;
        vertical-align: middle;
        margin: 15px;
        width: 250px;
      }
      &:last-child{
        p{
          width: 100px;
          margin: 15px 0 15px 15px;
        }
      }
      .at-button-primary{
        font-size: 16px;
      }
    }
  }
}
body.accessible-contrast {
  .sidebar, .main, .panel, .int, .cont-b{
    background: #000!important;
  }
  .main, .sidebar a{
    color: #fff!important;
  }
}
.sidebar-scroll{
  position: relative;
  .menu{
    padding-top: 100px;
  }
}

// .at-input-label .help{
//   font-size: .75rem;
//   padding: 0 12px;
//   color: #565656;
// }
// .at-input-label input[value=""] + .labelOut, input[value]:not([value=""]) + .labelOut{
//   display: block;
//   position: absolute;
//   padding: 1px 2px;
//   color: $neutral_2;
//   background: #fff;
//   font-size: .875rem;
//   z-index: 2;
//   -webkit-animation-name: labelIn;
//   animation-name: labelIn;
//   -webkit-animation-duration: 0.35s;
//   animation-duration: 0.35s;
//   -webkit-animation-direction: reverse;
//   animation-direction: reverse;
//   -webkit-animation-fill-mode: forwards;
//   animation-fill-mode: forwards;
// }
// .at-input-label input:focus{
//   border: 1px solid $secundary_2;
//   outline: none;
// }
// .at-input-label input:focus + .labelOut + .help{
//   color: $secundary_2;
// }
// .at-input-label input:focus + .labelOut, .at-input-label input[value]:not([value=""]) + .labelOut {
//   -webkit-animation-name: labelOut;
//   animation-name: labelOut;
//   -webkit-animation-duration: 0.35s;
//   animation-duration: 0.35s;
//   -webkit-animation-direction: normal;
//   animation-direction: normal;
//   -webkit-animation-fill-mode: forwards;
//   animation-fill-mode: forwards;
// }
// .at-input-label input:focus + .labelOut{
//   color: $secundary_2;
// }
// .at-selectlabel select[value=""] + .labelOut{
//   display: block;
//   position: absolute;
//   padding: 1px 2px;
//   color: $neutral_2;
//   background: #fff;
//   font-size: .875rem;
//   z-index: 2;
//   -webkit-animation-name: labelIn;
//   animation-name: labelIn;
//   -webkit-animation-duration: 0.35s;
//   animation-duration: 0.35s;
//   -webkit-animation-direction: reverse;
//   animation-direction: reverse;
//   -webkit-animation-fill-mode: forwards;
//   animation-fill-mode: forwards;
// }
